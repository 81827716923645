import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import {
  useSelectedPatientValue,
  useSelectedQuoteValue,
  useSettings,
  useViewport,
} from '../../hooks';
import { useSessionValue } from '../../hooks';
import { useTranslation } from 'react-i18next';
import LeftArrow from '../../img/PersonaImg/left-arrow.png';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Title from '../../components/Title';
import { tiendaRepositoryInstance } from '../../hexagonal-architecture-frontend-base/src/infrastructure/instances/tiendaRepository';
import { tiendaServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/tienda.service';
import { AVAILABLE_SETTINGS } from '../../consts/settings/available-settings';
import { Button } from '../../components/Buttons/button';
import classNames from 'classnames/bind';
import styles from './transfer-payment.module.css';
import { TopMenu } from '../../components';

const cx = classNames.bind(styles);

const TransferPayment = () => {
  const { viewportWidth } = useViewport();
  const { selectedPatient } = useSelectedPatientValue();
  const { userInfo } = useSessionValue();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedQuote } = useSelectedQuoteValue();
  const { getSettingKey } = useSettings();

  const [showMailSended, setShowMailSended] = useState<boolean>(false);
  const [isMailSent, setIsMailSended] = useState<boolean>(false);

  const tutorName = userInfo?.name;
  const isScreenBig = viewportWidth > 768;
  const textBase = 'pages.informeDetallado';
  const topMenuProperties: any = {
    title: `${GlobalService.uppercaseFirstLetter(t(`${textBase}.mainTitle`))}: ${
      selectedPatient.title
    }`,
    fixed: isScreenBig,
    user: tutorName,
  };
  let mail = getSettingKey(AVAILABLE_SETTINGS.DOCUMENTS);
  mail = mail.substring(mail.indexOf(':') + 1);
  const dataToHold = {
    consentId: selectedPatient.dealDetails.Archivo_Consentimientos?.length
      ? selectedPatient.dealDetails.Archivo_Consentimientos[0].id
      : '',
    contactConsentId: selectedPatient.Consentimientos?.length
      ? selectedPatient.Consentimientos[0].id
      : '',
    dealId: selectedPatient.dealDetails.id,
    dealName: selectedPatient.dealDetails.Deal_Name,
    docId: '',
    patientId: selectedPatient.id,
    patientName: selectedPatient.title,
    reqId: '',
  };
  const account = getSettingKey(AVAILABLE_SETTINGS.TRANSFER_ACC);
  const total = selectedQuote?.Grand_Total;

  const handleOnClickEmail = () => {
    if (!isMailSent) {
      console.log('Sending email');
  
      const salut = `${GlobalService.uppercaseFirstLetter(t('modal.transferencia.salut'))} ${dataToHold.patientName}`;
      const subject = `${GlobalService.uppercaseFirstLetter(t('modal.transferencia.subject'))} ${dataToHold.patientName}`;
      
      const body = `
        <div style="font-family: Arial, sans-serif;">
        <div style="margin-bottom: 20px;">
            <p style="font-weight: 700; font-size: larger;">${GlobalService.uppercaseFirstLetter(t('modal.transferencia.titleEmailTransfer'))}</p>
          </div>
          <div style="margin-bottom: 20px;">
            <p style="font-weight: 700;  color: #EB6D81;">${GlobalService.uppercaseFirstLetter(t('modal.transferencia.accountHolder'))}</p>
            <p style="background-color: #f4f4f4; padding: 10px; border-radius: 5px;">LURA CARE (DENTAL RESIDENCY)</p>
          </div>
          <div style="margin-bottom: 20px;">
            <p style="font-weight: 700;  color: #EB6D81;">${GlobalService.uppercaseFirstLetter(t('modal.transferencia.accountNum'))}</p>
            <p style="background-color: #f4f4f4; padding: 10px; border-radius: 5px;">${getSettingKey(AVAILABLE_SETTINGS.TRANSFER_ACC)}</p>
          </div>
          <div style="margin-bottom: 20px;">
            <p style="font-weight: 700;  color: #EB6D81;">${GlobalService.uppercaseFirstLetter(t('modal.transferencia.amount'))}</p>
            <p style="background-color: #f4f4f4; padding: 10px; border-radius: 5px;">${total} €</p>
          </div>
          <div style="margin-bottom: 20px;">
            <p style="font-weight: 700;  color: #EB6D81;">${GlobalService.uppercaseFirstLetter(t('modal.transferencia.concept'))}</p>
            <p style="background-color: #f4f4f4; padding: 10px; border-radius: 5px;">${selectedPatient.title}</p>
          </div>
          <div style="margin-bottom: 20px;">
            <p style="font-weight: 400; color: #4a4a4a;">${GlobalService.uppercaseFirstLetter(t('modal.transferencia.bodyText'))} ${mail}</p>
          </div>
          <div>
            <p style="font-weight: 700; color: #4a4a4a;">${GlobalService.uppercaseFirstLetter(t('modal.transferencia.thanks'))}</p>
          </div>
        </div>
      `;
  
      const details = {
        body,
        mail: userInfo?.email ?? '',
        subject,
        salut,
      };
  
      tiendaServices(tiendaRepositoryInstance)
        .sendTransfMail(details)
        .then(() => {
          setIsMailSended(true);
        });
    }
  };
  


  return (
    <div className={cx('main-container')}>
      {isScreenBig ? (
        <div className={cx('top-menu-container')}>
          <TopMenu {...topMenuProperties} />
        </div>
      ) : (
        <TopMenu {...topMenuProperties} />
      )}
      <div className={cx('body-container-main')}>
        {isScreenBig && <div className='flex text-left mb-6'>
          <img
            onClick={() => {
              navigate(-1);
            }}
            src={LeftArrow}
            alt='Back'
            className='Notificaciones-Arrow cursor-pointer'
          />
          <Title
            text={`${GlobalService.uppercaseFirstLetter(
              t(`pages.paymentMethod.paymenttransfer`),
            )} :`}
            color='black'
            size={'24px'}
            margin={'0px 0px 0px 30px'}
          />
        </div>}
        <div className={cx('body-container', 'flex flex-col gap-y-2 mx-2 bg-white')}>
          <p>
            {`${t(`modal.transferencia.maketransfer`)} ${selectedQuote?.Grand_Total}€ ${t(
              `modal.transferencia.totheaccount`,
            )}`}
          </p>
          <p className={cx('text-red')}>
            <strong >
              {GlobalService.uppercaseFirstLetter(t('modal.transferencia.accountHolder'))}
            </strong>
          </p>
          <p>LURA CARE (DENTAL RESIDENCY)</p>
          <p className={cx('text-red')}>
            <strong >
              {GlobalService.uppercaseFirstLetter(t('modal.transferencia.accountNum'))}
            </strong>
          </p>
          <p>{account}</p>
          <p className='font-bold'>
            <strong className={cx('text-red')}>{GlobalService.uppercaseFirstLetter(t('modal.transferencia.amount'))}</strong>
          </p>
          <p>{total} €</p>
          <p className={cx('text-red')}>
            <strong>{GlobalService.uppercaseFirstLetter(t('modal.transferencia.concept'))}</strong>
          </p>
          <p>{selectedPatient.title}</p>
          <p className={cx('mt-14')}>
            {GlobalService.uppercaseFirstLetter(t('modal.transferencia.bodyText'))} <span className={cx('mt-14' , 'text-red')}>{mail}</span>
          </p>
          <p className='mt-3.5'>
            {GlobalService.uppercaseFirstLetter(t('modal.transferencia.thanks'))}
          </p>
          <Button
            onClick={() => {
              setShowMailSended(true);
              handleOnClickEmail();
            }}
          >
            {GlobalService.uppercaseJustFirstLetter(t('modal.transferencia.sendinfo'))}
          </Button>
          {showMailSended ? (
            <div className='mt-8' style={{ color: 'green' }}>
              {GlobalService.uppercaseJustFirstLetter(t('modal.transferencia.mailsended'))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TransferPayment;
