import PrivateRoutes from './routes/PrivateRoutes';
import PublicRoutes from './routes/PublicRoutes';
import { Navigate, Route, Routes } from 'react-router-dom';

// Components
import { AllPatients, InformeDetallado, Help, Login, Signup } from './views';
import Blog from './views/Blog';
import Carrito from './views/Carrito';
import ChangePassword from './views/ChangePassword';
import DatosPersonaDocumentos from './views/DatosPersonaDocumentos';
import DatosPersonaYServicio from './views/DatosPersonaYServicio';
import Documentos from './views/Documentos';
import Facturas from './views/Facturas';
import ForgotPassword from './views/ForgotPassword';
import Informes from './views/informes/Informes';
import Notificaciones from './views/Notificaciones';
import Pagos from './views/Pagos';
import Pedidos from './views/Pedidos';
// import Personas from './views/Personas';
import Producto from './views/Producto';
import ResetPassword from './views/ResetPassword';
import Servicios from './views/Servicios';
import SolicitudVisitaServicio from './views/SolicitudVisitaServicio';
import Subscripciones from './views/Subscripciones';
import Tienda from './views/Tienda';
import Visitas from './views/Visitas';
import Pago from './views/Pago';
import Signature from './views/Signature';
import PaymentMethod from './views/PaymentMethod';
import { Profile } from './views/profile/profile';
import { useSession } from './hooks';
import { MainLayout, PagesLayout } from './components/layouts';
import SplitPayment from './views/split-payment/split-payment';
import TransferPayment from './views/transfer-payment/transfer-payment';
import VisaPayment from './views/VisaPayment/visa-payment';

function App() {
  const { isUserLogged } = useSession();

  return (
    <MainLayout>
      <PagesLayout>
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route
              path='/'
              element={isUserLogged ? <Navigate to='/allPatients' replace /> : <Blog />}
            />
            <Route path='/changePassword' element={<ChangePassword />} />
            <Route path='/login' element={<Login />} />
            <Route path='/resetPassword/:token' element={<ResetPassword />} />
            <Route path='/signup' element={<Signup />} />

            <Route path='/blog' element={<Blog />} />
            <Route path='/forgotPassword' element={<ForgotPassword />} />
            <Route path='/help' element={<Help />} />
            {/* <Route path='/users/:userId/:verify' element={<VerifyEmail />} /> */}
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path='/' element={!isUserLogged ? <Navigate to='/login' replace /> : <Blog />} />
            {/* <Route path='/blog' element={<Blog />} /> */}
            <Route path='/allPatients' element={<AllPatients />} />
            <Route path='/carrito' element={<Carrito />} />
            <Route path='/datosDocumentos/:patientId/:docId' element={<DatosPersonaDocumentos />} />
            <Route path='/datosPersona/:patientId' element={<DatosPersonaYServicio />} />
            <Route path='/documentos/:patientId' element={<Documentos />} />
            <Route path='/facturas/:patientId' element={<Facturas />} />
            <Route path='/informeDetallado/:patientId/:reportId' element={<InformeDetallado />} />
            <Route path='/informeDetallado/:patientId/:reportId/pago' element={<Pago />} />
            <Route
              path='/informeDetallado/:patientId/:reportId/paymentmethod'
              element={<PaymentMethod />}
            />
            <Route
              path='/informeDetallado/:patientId/:reportId/paymentmethod/visapayment'
              element={<VisaPayment />}
            />
            <Route
              path='/informeDetallado/:patientId/:reportId/paymentmethod/transferpayment'
              element={<TransferPayment />}
            />
            <Route
              path='/informeDetallado/:patientId/:reportId/paymentmethod/splitpayment'
              element={<SplitPayment />}
            />
            <Route
              path='/informeDetallado/:patientId/:reportId/signature'
              element={<Signature />}
            />
            <Route path='/informes/:patientId' element={<Informes />} />
            <Route path='/notificaciones' element={<Notificaciones />} />
            <Route path='/pagos' element={<Pagos />} />
            <Route path='/pedidos' element={<Pedidos />} />
            {/* <Route path='/personas' element={<Personas />} /> */}
            <Route path='/personas' element={<AllPatients />} />
            <Route path='/producto/:itemId' element={<Producto />} />
            <Route path='/servicios' element={<Servicios />} />
            <Route path='/solicitudVisitaServicio' element={<SolicitudVisitaServicio />} />
            <Route path='/subscripciones' element={<Subscripciones />} />
            <Route path='/tienda' element={<Tienda />} />
            <Route path='/user/blog' element={<Blog />} />
            <Route path='/user/help' element={<Help />} />
            <Route path='/visitas/:patientId' element={<Visitas />} />
            <Route path='/profile' element={<Profile />} />
          </Route>
        </Routes>
      </PagesLayout>
    </MainLayout>
  );
}

export default App;
