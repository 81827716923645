import { Navigate, Outlet } from 'react-router-dom';
import { useSession } from '../hooks';

const PublicRoutes = () => {
  const { isUserLogged } = useSession();

  return <>{!isUserLogged ? <Outlet /> : <Navigate to={'/allPatients'} />}</>;
};

export default PublicRoutes;
