import axios from 'axios';
import { AddressData } from '../../../ts';

export const getUserAddressService = async () => {
  const type = 'addressenviamentcomanda';
  try {
    const response = await axios.get<AddressData>(`/addresses${type ? `?types=${type}` : ''}`);

    console.log('response', response);

    return response.data;
  } catch (error) {
    throw new Error('errors.addressesNotFound');
  }
};
