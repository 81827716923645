import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { ButtonBase } from '../components/Buttons/button-base';
import ModalInfo from '../components/Modals/ModalInfo';
import ModalRecurringPurchase from '../components/Modals/ModalRecurringPurchase';
import RegularSpinner from '../components/Spinner/RegularSpinner';
import { TopMenu } from '../components';
import ModalYesNo from '../components/Modals/ModalYesNo';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { tiendaServices } from '../hexagonal-architecture-frontend-base/src/infrastructure/services/tienda.service';
import { tiendaRepositoryInstance } from '../hexagonal-architecture-frontend-base/src/infrastructure/instances/tiendaRepository';
import { useTranslation } from 'react-i18next';

// Interfaces
import { IModalInfoProps } from '../hexagonal-architecture-frontend-base/src/domain/models/IModals';
import {
  IOrder,
  IOrderItem,
} from '../hexagonal-architecture-frontend-base/src/domain/models/IPago';
import { IPatientReport } from '../hexagonal-architecture-frontend-base/src/domain/models/IPersona';
// CSS
import '../styles/Pagos/Pagos.css';

// Hooks
import {
  useViewport,
  usePatientsValue,
  useSessionValue,
  useSetSelectedPatient,
  useSetUrlToReturn,
} from '../hooks';

const Pagos = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userInfo } = useSessionValue();
  const { allQuotes, isLoading: loadingServiciosItems } = usePatientsValue();
  const { setSelectedPatient } = useSetSelectedPatient();
  const { setUrlToReturn } = useSetUrlToReturn();

  console.log('here');

  /**********
   * States *
   **********/
  const [loadingPedidosItems, setLoadingPedidosItems] = useState<boolean>(true);
  const [loadingSuscriptionsItems, setLoadingSuscriptionsItems] = useState<boolean>(true);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [modalQuestion, setModalQuestion] = useState<string>('');
  const [months, setMonths] = useState<number>(1);
  const [numberItems] = useState<number>(2);
  const [numberNonPatientAccessItems] = useState<number>(4);
  const [patientsAccess, setPatientsAcces] = useState<boolean>(false);
  const [pedidosItems, setPedidosItems] = useState<IOrder[]>([]);
  const [selectedSubscription, setSelectedSubscription] = useState<IOrder | null>(null);
  const [showModalMessage, setShowModalMessage] = useState<boolean>(false);
  const [showRecurringModal, setShowRecurringModal] = useState<boolean>(false);
  const [showRecurringModalItem, setShowRecurringModalItem] = useState<IOrder>();
  const [showYesNoModal, setShowYesNoModal] = useState<boolean>(false);
  const [suscriptionsItems, setSuscriptionsItems] = useState<IOrder[]>([]);
  const { viewportWidth } = useViewport();

  /*********
   * Hooks *
   *********/
  useEffect(() => {
    getSubscriptionsData();
    getPedidosItemsData();
  }, []);

  useEffect(() => {
    if (userInfo == null) return;
    setPatientsAcces(userInfo.patientsAccess);
    // tiendaServices(tiendaRepositoryInstance)
    //   .getSuscriptionsItems(numberNonPatientAccessItems)
    //   .then(data => {
    //     if (typeof data !== 'string') {
    //       setSuscriptionsItems(data);
    //     } else {
    //       setSuscriptionsItems([]);
    //     }

    //     setLoadingSuscriptionsItems(false);
    //   });
    // tiendaServices(tiendaRepositoryInstance)
    //   .getPedidosItems(numberNonPatientAccessItems)
    //   .then(data => {
    //     if (typeof data !== 'string') {
    //       setPedidosItems(data);
    //     } else {
    //       setPedidosItems([]);
    //     }

    //     setLoadingPedidosItems(false);
    //   });
  }, [userInfo]);

  /************
   * Handlers *
   ************/
  const handleClickYes = () => {
    if (
      modalQuestion === GlobalService.uppercaseFirstLetter(t('pages.pagos.updateSubscription')) &&
      selectedSubscription
    )
      handleUpdateSubscription(months, selectedSubscription);
    else handleDeleteSubscription();
    setShowYesNoModal(false);
  };

  const handleCloseModal = () => {
    setShowRecurringModal(false);
  };

  const handleDeleteSubscription = async () => {
    if (selectedSubscription && selectedSubscription.id && selectedSubscription.subscriptionId) {
      await tiendaServices(tiendaRepositoryInstance)
        .cancelStripeSubscription(selectedSubscription.subscriptionId)
        .then(async (res: string) => {
          if (res === 'succeeded') {
            const editedSelectedSubscription = { ...selectedSubscription, status: 'canceled' };
            await tiendaServices(tiendaRepositoryInstance)
              .updateOrder(editedSelectedSubscription)
              .then((res: string) => {
                if (res === 'succeeded') {
                  setModalMessage('pages.pagos.succeedeedCancelSubscription');
                  setShowModalMessage(true);
                  setShowRecurringModal(false);
                  setShowYesNoModal(false);
                  getSubscriptionsData();
                  getPedidosItemsData();
                } else {
                  setModalMessage(res);
                  setShowModalMessage(true);
                }
              });
          }
        });
    } else {
      setModalMessage('errors.genericError');
      setShowModalMessage(true);
    }
  };

  const handleShowQuote = (item: IPatientReport) => {
    if (allQuotes == null) return;
    setSelectedPatient(item.Contact_Name.id);
    setUrlToReturn('/pagos');
    navigate(`/informeDetallado/${item.Contact_Name.id}/${item.id}`);
  };

  const handleShowRecurringModal = (item: IOrder) => {
    setShowRecurringModalItem(item);
    setShowRecurringModal(true);
  };

  const handleShowYesNoModal = (order?: IOrder, isCancel?: boolean, months?: number) => {
    if (order) {
      setSelectedSubscription(order);
      if (isCancel)
        setModalQuestion(GlobalService.uppercaseFirstLetter(t('pages.pagos.cancelSubscription')));
      if (months) {
        setMonths(months);
        setModalQuestion(GlobalService.uppercaseFirstLetter(t('pages.pagos.updateSubscription')));
      }
      setShowYesNoModal(true);
    }
  };

  const handleUpdateSubscription = async (months: number, order?: IOrder) => {
    if (months && order && order.subscriptionId && order.id) {
      const editedOrder = { ...order, frequency: months };
      await tiendaServices(tiendaRepositoryInstance)
        .updateStripeSubscription(months, order.subscriptionId)
        .then(async (res: string) => {
          if (res === 'succeeded') {
            await tiendaServices(tiendaRepositoryInstance)
              .updateOrder(editedOrder)
              .then((res: string) => {
                if (res === 'succeeded') {
                  setModalMessage('pages.pagos.succeedeedUpdateSubscription');
                  setShowModalMessage(true);
                  setShowRecurringModal(false);
                  getSubscriptionsData();
                  getPedidosItemsData();
                } else {
                  setModalMessage(res);
                  setShowModalMessage(true);
                }
              });
          }
        });
    } else {
      setModalMessage('errors.genericError');
      setShowModalMessage(true);
    }
  };

  /***********
   * Helpers *
   ***********/
  const getImageFromItems = (item: IOrder): string => {
    let imageUrl = '';

    if (item && item.items && Array.isArray(item.items) && item.items.length > 0) {
      item.items.map((item: IOrderItem) => {
        item.Products.image.map((image: string) => {
          if (image !== '' && imageUrl === '') imageUrl = image;
        });
      });
    }
    return imageUrl;
  };

  const getPedidosItemsData = async () => {
    await tiendaServices(tiendaRepositoryInstance)
      .getPedidosItems(patientsAccess ? numberItems : numberNonPatientAccessItems)
      .then(data => {
        if (typeof data !== 'string') {
          setPedidosItems(data);
        } else {
          setPedidosItems([]);
        }
        setLoadingPedidosItems(false);
      });
  };

  const getPriceFromItems = (item: IOrder): string => {
    let value = 0;

    if (item && item.items && Array.isArray(item.items) && item.items.length > 0) {
      value = item?.items.reduce(
        (accumulator, currentValue) =>
          accumulator +
          Number(currentValue.Products.price.toString().replace(',', '.')) * currentValue.quantity,
        0,
      );
    }
    return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
  };

  const getSubscriptionsData = async () => {
    await tiendaServices(tiendaRepositoryInstance)
      .getSuscriptionsItems(patientsAccess ? numberItems : numberNonPatientAccessItems)
      .then(data => {
        if (typeof data !== 'string') {
          setSuscriptionsItems(data);
        } else {
          setSuscriptionsItems([]);
        }

        setLoadingSuscriptionsItems(false);
      });
  };

  const parseDate = (date: string): string => {
    const dateAux = GlobalService.parseDate(date);
    return dateAux.day + '/' + dateAux.month + '/' + dateAux.year;
  };

  const parseEstadoDePago = (estado: string): string => {
    if (estado !== null) return estado;
    return '-';
  };

  const parseFutureDate = (date: string, frequency: number): string => {
    const dateAux = GlobalService.addTimeToDate(date, 0, frequency, 0);
    return dateAux.day + '/' + dateAux.month + '/' + dateAux.year;
  };

  const parseTipoDePago = (type: string): string => {
    if (type !== null) return type;
    return '-';
  };

  /**************
   * JSX Values *
   **************/
  document.title = `LuraCare - ${GlobalService.uppercaseFirstLetter(t('pages.pagos.title'))}`;
  const isScreenBig = viewportWidth > 768;
  const noData = <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>;

  const seeMoreButton = (addresToGo: string) => (
    <div className='flex items-center justify-center gap-2'>
      <ButtonBase
        text={GlobalService.uppercaseFirstLetter(t('seeMore'))}
        background='inherit'
        color='#808080'
        extraClass='border-b 3xs:mt-0 md:mt-3'
        paddingX={0}
        paddingY={0}
        fontSize={isScreenBig ? '16px' : '12px'}
        onClick={() => {
          navigate(addresToGo);
        }}
      />
    </div>
  );

  const formatedServiciosItems = allQuotes?.map((item: any, index: any) => (
    <div key={index} className='tratamiento-container mb-4'>
      <div className='flex justify-between'>
        <div className={isScreenBig ? '' : 'subtitulo-small'}>
          <p>{GlobalService.uppercaseFirstLetter(t('pages.pagos.treatmentOf'))}:</p>
          <p>
            <strong className={isScreenBig ? '' : 'valor-small'}>{item?.Subject}</strong>
          </p>
        </div>
        <p style={{ color: '#28337d' }}>
          <strong>{item.price}</strong>
        </p>
      </div>
      <div className='mt-4 flex justify-between'>
        <div>
          <p className='text-xs'>
            <strong>
              {GlobalService.uppercaseFirstLetter(t('pages.pagos.paymentType'))}:{' '}
              {parseTipoDePago(item?.Tipo_de_Pago)}
            </strong>
          </p>
          <p className='text-xs mt-1'>
            <strong>
              {GlobalService.uppercaseFirstLetter(t('pages.pagos.state'))}:{' '}
              {parseEstadoDePago(item?.Estado_del_pago)}
            </strong>
          </p>
        </div>
        <ButtonBase
          background='none'
          border='1px solid #28337d'
          borderRadius='50px'
          buttonWidth={isScreenBig ? '122px' : '90px'}
          color='#28337d'
          extraClass='informe-button'
          fontSize={isScreenBig ? '16px' : '10px'}
          onClick={() => handleShowQuote(item)}
          paddingX={6}
          paddingY={4}
          text={GlobalService.uppercaseFirstLetter(t('pages.pagos.seeReport'))}
        />
      </div>
    </div>
  ));

  console.log('formatedServiciosItems', formatedServiciosItems);

  const formatedSubscriptionsItems = suscriptionsItems.length
    ? suscriptionsItems.map((item: any, index: any) => {
        if (index < 2)
          return (
            <div
              key={index}
              className='relative p-2 md:p-4 flex justify-between servicios-container'
              style={{ background: 'white' }}
            >
              <div className='flex'>
                <img
                  src={getImageFromItems(item)}
                  alt=''
                  className='w-[70px] h-[70px] md:w-[120px] md:h-[120px] border-2'
                />
                <div className='flex flex-col justify-between ml-3'>
                  <div>
                    <p>
                      <strong className={isScreenBig ? '' : 'valor-small'}>
                        {item?.items[0]?.Products.name}
                      </strong>
                    </p>
                    <p className='mb-2' style={{ color: '#28337d' }}>
                      <strong>{getPriceFromItems(item)}</strong>
                    </p>
                  </div>
                  <div className='text-xs'>
                    <p>
                      <strong>
                        {GlobalService.uppercaseFirstLetter(t('pages.pagos.nextDelivery'))}:{' '}
                        {parseFutureDate(item.lastReceivedAt, item.frequency)}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className='absolute top-3 right-0 w-24 md:w-32'>
                <ButtonBase
                  text={GlobalService.uppercaseFirstLetter(t('pages.pagos.modify'))}
                  background='white'
                  color={item.status !== 'paid' || !item.recurrent ? 'grey' : '#28337d'}
                  border={
                    item.status !== 'paid' || !item.recurrent
                      ? '1px solid grey'
                      : '1px solid #28337d'
                  }
                  borderRadius='50px'
                  paddingX={6}
                  paddingY={isScreenBig ? 4 : 2}
                  extraClass='informe-button'
                  fontSize={isScreenBig ? '16px' : '12px'}
                  buttonWidth={isScreenBig ? '111px' : '85px'}
                  minWidth={isScreenBig ? '80px' : '73px'}
                  minHeight={isScreenBig ? '38px' : '27px'}
                  onClick={() => handleShowRecurringModal(item)}
                  disabled={item.status !== 'paid' || !item.recurrent}
                />
              </div>
            </div>
          );
      })
    : noData;

  const formatedPedidosItems = pedidosItems.length
    ? pedidosItems.map((item: any, index: number) => {
        if (index < 2)
          return (
            <div
              key={index}
              className='p-2 md:p-4 flex justify-between servicios-container'
              style={{ background: 'white' }}
            >
              <div className='flex w-full'>
                <img
                  src={getImageFromItems(item)}
                  alt=''
                  className='w-[95px] h-[95px] md:w-[120px] md:h-[120px] border-2'
                />
                <div className='flex flex-col justify-between ml-3'>
                  <div>
                    <p>
                      <strong className={isScreenBig ? '' : 'valor-small'}>
                        {item?.items[0]?.Products.name}
                      </strong>
                    </p>
                    <p className='mb-2' style={{ color: '#28337d' }}>
                      <strong>{getPriceFromItems(item)}</strong>
                    </p>
                  </div>
                  <div className='text-xs'>
                    <p className='mt-2'>
                      <strong>
                        {GlobalService.uppercaseFirstLetter(t('pages.pagos.orderStatus'))}:{' '}
                        {item.status}
                      </strong>
                    </p>
                    <p>
                      <strong>
                        {GlobalService.uppercaseFirstLetter(t('pages.pagos.nextDelivery'))}:{' '}
                        {parseDate(item.lastReceivedAt)}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
      })
    : noData;

  const recurringModal = (
    <ModalRecurringPurchase
      orderItem={showRecurringModalItem}
      onclickCancel={order => handleShowYesNoModal(order, true)}
      onclickContinue={(months, order) => handleShowYesNoModal(order, false, months)}
      cancelButtonText={GlobalService.uppercaseFirstLetter(t('pages.tienda.cancelOrder'))}
      onClose={handleCloseModal}
    />
  );

  const YesNoModal = (
    <ModalYesNo
      onClickYes={handleClickYes}
      onClickNo={() => setShowYesNoModal(false)}
      bodyQuestion={modalQuestion}
    />
  );

  const messageModal = (
    <ModalInfo bodyText={modalMessage} onClickOk={() => setShowModalMessage(false)} />
  );

  return (
    <div className={`w-full ${isScreenBig ? '' : 'bg-gray h-full'}`}>
      <TopMenu title={GlobalService.uppercaseFirstLetter(t('pages.pagos.title'))} />
      {patientsAccess ? (
        <div
          className={`3xs:block lg:grid grid-cols-2 gap-y-4 gap-x-4 w-full p-6 pagos-height-access my-[80px] md:my-0`}
        >
          <div className='w-full'>
            <p className='3xs:titulo-small md:text-xl 3xs:mb-2 md:mb-4'>
              <strong>{GlobalService.uppercaseFirstLetter(t('pages.pagos.services'))}:</strong>
            </p>
            {loadingServiciosItems ? (
              <RegularSpinner />
            ) : allQuotes?.length ? (
              formatedServiciosItems
            ) : (
              noData
            )}
            {!loadingServiciosItems && allQuotes != null && allQuotes.length > 3
              ? seeMoreButton('/servicios')
              : null}
          </div>
          {/* <div className='w-full mb-12 md:mb-4'>
            <div>
              <p className='3xs:titulo-small md:text-xl md:mb-2'>
                <strong>
                  {GlobalService.uppercaseFirstLetter(t('pages.pagos.subscriptions'))}:
                </strong>
              </p>
              <div className='grid grid-cols-1 gap-y-4 mb-4'>
                {loadingSuscriptionsItems ? (
                  <RegularSpinner />
                ) : suscriptionsItems.length ? (
                  formatedSubscriptionsItems
                ) : (
                  noData
                )}
              </div>
              {!loadingSuscriptionsItems && suscriptionsItems.length > 2
                ? seeMoreButton('/subscripciones')
                : null}
            </div>
            <div>
              <p className='3xs:titulo-small md:text-xl md:mb-2'>
                <strong>{GlobalService.uppercaseFirstLetter(t('pages.pagos.orders'))}:</strong>
              </p>
              <div className='grid grid-cols-1 gap-y-4 mb-4'>
                {loadingPedidosItems ? <RegularSpinner /> : formatedPedidosItems}
              </div>
              {!loadingPedidosItems && pedidosItems.length > 2 ? seeMoreButton('/pedidos') : null}
            </div>
          </div> */}
        </div>
      ) : (
        <div></div>
        // <div
        //   className={`3xs:block lg:grid grid-cols-2 gap-y-4 gap-x-4 w-full p-6 pagos-height mb-[80px] md:my-0`}
        // >
        //   <div>
        //     <p className='3xs:titulo-small md:text-xl md:mb-4'>
        //       <strong>{GlobalService.uppercaseFirstLetter(t('pages.pagos.subscriptions'))}:</strong>
        //     </p>
        //     <div className='grid grid-cols-1 gap-y-4 mb-4'>
        //       {loadingSuscriptionsItems ? (
        //         <RegularSpinner />
        //       ) : suscriptionsItems.length ? (
        //         formatedSubscriptionsItems
        //       ) : (
        //         noData
        //       )}
        //     </div>
        //     {!loadingSuscriptionsItems && suscriptionsItems.length > 2
        //       ? seeMoreButton('/subscripciones')
        //       : null}
        //   </div>
        //   <div>
        //     <p className='3xs:titulo-small md:text-xl md:mb-4'>
        //       <strong>{GlobalService.uppercaseFirstLetter(t('pages.pagos.orders'))}:</strong>
        //     </p>
        //     <div className='grid grid-cols-1 gap-y-4 mb-4'>
        //       {loadingPedidosItems ? <RegularSpinner /> : formatedPedidosItems}
        //     </div>
        //     {!loadingPedidosItems && pedidosItems.length > 2 ? seeMoreButton('/pedidos') : null}
        //   </div>
        // </div>
      )}
      {showRecurringModal ? recurringModal : null}
      {showYesNoModal ? YesNoModal : null}
      {showModalMessage ? messageModal : null}
    </div>
  );
};

export default Pagos;
