import { useForm } from '@tanstack/react-form';
import styles from './mobile-verification-form.module.css';
import classNames from 'classnames/bind';
import { FC } from 'react';
import { Button } from '../../Buttons';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { SendSMSCode } from '../../login-signup/signup/send-sms-code';
import { Input } from '../../../components';

const cx = classNames.bind(styles);

interface SignupForm1Props {
  mobile: string;
  goBack: () => void;
  handleSubmit: (smsCode: string) => Promise<void>;
  clearSubmitError: () => void;
  submitError?: string;
}

export const MobileVerificationForm: FC<SignupForm1Props> = ({
  mobile,
  goBack,
  handleSubmit,
  clearSubmitError,
  submitError,
}) => {
  const { t } = useTranslation();

  const form = useForm({
    defaultValues: {
      mobile,
      smsCode: '',
    },
    onSubmit: async values => {
      await handleSubmit(values.value.smsCode);
    },
  });

  return (
    <div className={cx('signup-form-container')}>
      <p>
        {GlobalService.uppercaseAfterPointFirstsLetters(
          t('modal.verifyMobile.verificationCodeExplanation', { mobile }),
        )}
      </p>

      <form
        className={cx('signup-form')}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <form.Field
          name='smsCode'
          validators={{
            onChange: ({ value }) => {
              if (value.length !== 6) {
                return 'modal.verifyMobile.errors.incorrectCodeFormat';
              }
            },
          }}
          // eslint-disable-next-line react/no-children-prop
          children={field => (
            <Input
              id={field.name}
              label={'modal.verifyMobile.smsCode'}
              type='text'
              value={field.state.value}
              onChange={e => {
                if (Number.isNaN(Number(e))) return;
                if (e.includes('.')) return;
                if (e.length > 6) return field.handleChange(e.substring(0, 6));
                clearSubmitError();
                field.handleChange(e);
              }}
              errorMessage={field.state.meta.errors.join(', ')}
            />
          )}
        />

        <SendSMSCode mobile={mobile} isVerifyLoading={form.state.isSubmitting} />

        {submitError ? (
          <p className={cx('error-text')}>
            {GlobalService.uppercaseAfterPointFirstsLetters(t(submitError))}
          </p>
        ) : null}

        <Button
          fullWidth={true}
          disabled={!form.state.canSubmit && !form.state.isSubmitting}
          type='submit'
          isLoading={form.state.isSubmitting}
        >
          {GlobalService.uppercaseFirstLetter(t('modal.verifyMobile.submitCode'))}
        </Button>

        <Button styleType='secondary' onClick={goBack} fullWidth={true}>
          {GlobalService.uppercaseFirstLetter(t('modal.verifyMobile.goBack'))}
        </Button>
      </form>
    </div>
  );
};
