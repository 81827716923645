import { FC, useLayoutEffect } from 'react';
import { useSetTopMenu } from '../../../hooks';
import { TopMenuProps } from '../../../ts';

export const TopMenu: FC<TopMenuProps> = props => {
  const { setTopMenuProps } = useSetTopMenu();

  useLayoutEffect(() => {
    setTopMenuProps(props);
  }, [props]);

  return null;
};
