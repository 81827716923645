import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import RegularSpinner from '../components/Spinner/RegularSpinner';
import Title from '../components/Title';
import { TopMenu } from '../components';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Models
import {
  ISignAction,
  ISignField,
} from '../hexagonal-architecture-frontend-base/src/domain/models/IZohoSign';
import { IPatient } from '../hexagonal-architecture-frontend-base/src/domain/models/IPersona';

// CSS
import '../styles/Personas/Personas.css';

// Images
import LeftArrow from '../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';
import { useViewport } from '../hooks/use-viewport';
import {
  useGetDocumentInfo,
  useGetTemplateInfo,
  usePatientsValue,
  useSelectedPatient,
  useSessionValue,
  useShowErrorPopup,
  useSignUrl,
} from '../hooks';
import { SignAction, SignTemplate } from '../ts';

let timer: any;

const Signature = () => {
  const { patientId, reportId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userInfo } = useSessionValue();
  const { patients } = usePatientsValue();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();
  const { getTemplateInfo } = useGetTemplateInfo();
  const { getSignUrl, signUrl } = useSignUrl();
  const { getDocumentInfo } = useGetDocumentInfo();
  const { showErrorPopup } = useShowErrorPopup();
  const dataToHoldRef = useRef({
    consentId: selectedPatient.dealDetails.Archivo_Consentimientos?.length
      ? selectedPatient.dealDetails.Archivo_Consentimientos[0].id
      : '',
    contactConsentId: selectedPatient.Consentimientos?.length
      ? selectedPatient.Consentimientos[0].id
      : '',
    dealId: selectedPatient.dealDetails.id,
    dealName: selectedPatient.dealDetails.Deal_Name,
    docId: signUrl?.docId,
    patientId: selectedPatient.id,
    patientName: selectedPatient.title,
    reqId: signUrl?.reqId,
  });

  dataToHoldRef.current = {
    consentId: selectedPatient.dealDetails.Archivo_Consentimientos?.length
      ? selectedPatient.dealDetails.Archivo_Consentimientos[0].id
      : '',
    contactConsentId: selectedPatient.Consentimientos?.length
      ? selectedPatient.Consentimientos[0].id
      : '',
    dealId: selectedPatient.dealDetails.id,
    dealName: selectedPatient.dealDetails.Deal_Name,
    docId: signUrl?.docId,
    patientId: selectedPatient.id,
    patientName: selectedPatient.title,
    reqId: signUrl?.reqId,
  };

  console.log('PARAMS ', patientId, reportId);
  console.log('SIGN URL DOC ID ', dataToHoldRef.current.docId);

  /**********
   * States *
   **********/
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const [textToShow, setTextToShow] = useState<string>('establishConnection');
  const { viewportWidth } = useViewport();
  const [getData, setGetData] = useState<boolean>(true);

  useEffect(() => {
    setSelectedPatient(patientId as string);
  }, [patients]);

  useEffect(() => {
    fetchData(selectedPatient);
  }, [selectedPatient]);

  /***********
   * Helpers *
   ***********/
  // Get template's info
  const fetchData = async (patient: IPatient) => {
    console.log('patient in fetch data in signature ', patient);
    getTemplateInfo(
      {},
      {
        onError: () => showErrorPopup('errors.signRequestFailed'),
        onSuccess: async templateData => {
          // Set the mandatory fields value
          console.log('TEMPLATE DATA IN GETTEMPLATEINFO ', templateData);
          const fieldData: { field_text_data: { [key: string]: string } } = {
            field_text_data: getFieldsData(
              templateData.templates.document_fields[0].fields,
              patient,
            ),
          };

          //Set the actions value
          const actions: SignAction[] = templateData.templates.actions;
          actions.forEach((action: ISignAction) => {
            action.recipient_name = selectedPatient.title;
            action.recipient_email = selectedPatient.email ?? '';
            action.verify_recipient = false;
            action.is_embedded = true;
            delete action.fields;
          });

          // Set the template values
          const template: SignTemplate = {
            actions: actions,
            field_data: fieldData,
            request_name: 'Autorizacion',
          };
          const dataToSend: { templates: SignTemplate } = {
            templates: template,
          };

          console.log('TEMPLATE DATA TO SEND ', dataToSend);

          getSignUrl(
            { data: dataToSend },
            {
              onError: () => showErrorPopup('errors.signRequestFailed'),
              onSuccess: () => {
                if (!timer) {
                  timer = setInterval(getDocumentSignState, 2500);
                }
              },
            },
          );
        },
      },
    );
  };

  const getFieldsData = (fields: ISignField[], patient: IPatient): { [key: string]: string } => {
    const fieldTextData: { [key: string]: string } = {};

    fields.forEach((field: ISignField) => {
      if (field.is_mandatory && !fieldTextData[field.field_label]) {
        switch (field.field_label) {
          case 'Tutor Full Name':
            fieldTextData[field.field_label] = userInfo?.name ?? '';
            break;
          case 'Tutor DNI':
            fieldTextData[field.field_label] = userInfo?.dni ?? '';
            break;
          case 'Person Name':
            fieldTextData[field.field_label] = patient.title;
            break;
          case 'Patient DNI':
            fieldTextData[field.field_label] =
              patient.dni || GlobalService.uppercaseFirstLetter(t('notAvailable'));
            break;
          case 'Residency Name':
            fieldTextData[field.field_label] = patient.residence;
        }
      }
    });
    return fieldTextData;
  };

  // This will hide the iframe when sign is completed and show the payment method modal
  const getDocumentSignState = () => {
    if (dataToHoldRef.current.docId == null || dataToHoldRef.current.reqId == null) {
      return;
    }

    getDocumentInfo(
      { reqId: dataToHoldRef.current.reqId },
      {
        onSuccess: documentInfo => {
          if (documentInfo.requests.request_status !== 'completed' || !getData) return;

          setGetData(false);
          stopInterval();
          setTextToShow('savingDoc');
          navigate(`/informeDetallado/${patientId}/${reportId}/paymentmethod`);
        },
      },
    );
  };

  const stopInterval = () => {
    clearInterval(timer);
    timer = null;
  };

  const isScreenBig = viewportWidth > 768;
  const isScreenTiny = viewportWidth < 321;
  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.mainTitle'))}:`;

  const desktopTopMenu = <TopMenu title={pageTitle} userName={selectedPatient.title} />;
  const desktopTitle = (
    <div className='flex text-left'>
      <img
        onClick={() => {
          navigate(`/informeDetallado/${patientId}/${reportId}/pago`);
        }}
        src={LeftArrow}
        alt='Back'
        className='Notificaciones-Arrow cursor-pointer'
      />
      <Title
        text={`${GlobalService.uppercaseFirstLetter(t('pages.pagos.paySignatureTitle'))}`}
        color='black'
        size={'24px'}
        margin={'0px 0px 0px 30px'}
      />
    </div>
  );

  const responsivePageTitle = GlobalService.uppercaseFirstLetter(
    t('pages.pagos.paySignatureTitle'),
  );

  return (
    <div className={`w-full flex flex-col h-full ${isScreenBig ? '' : 'bg-white'}`}>
      {isScreenBig ? (
        <>
          {desktopTopMenu}
          <div className='w-full h-full m-8 flex flex-col justify-between'>
            {desktopTitle}
            <div className='w-full h-full flex flex-col gap-8 items-center justify-around'>
              {showSpinner ? <RegularSpinner /> : null}
              {signUrl?.url ? (
                <iframe
                  title='signature-frame'
                  src={signUrl.url}
                  style={{ width: '100vh', height: '758px' }}
                  onLoad={() => setShowSpinner(false)}
                ></iframe>
              ) : (
                <p className='text-center max-w-xl font-bold'>
                  {GlobalService.uppercaseFirstLetter(t(`pages.signature.${textToShow}`))}
                </p>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='w-full h-full flex flex-col justify-between'>
            <TopMenu
              title={responsivePageTitle}
              leftIcon={LeftArrowSmall}
              leftIconLink={`/informeDetallado/${patientId}/${reportId}`}
            />
            <div className='w-full h-full flex flex-col items-center justify-center'>
              {showSpinner ? <RegularSpinner /> : null}
              {signUrl?.url ? (
                <iframe
                  title='signature-frame'
                  name='iframe'
                  src={signUrl.url}
                  style={{
                    width: '41vh',
                    height: '640px',
                    padding: isScreenTiny ? '70px 0px' : '45px 0px',
                  }}
                  onLoad={() => setShowSpinner(false)}
                />
              ) : (
                <p className='text-left max-w-sm px-8 font-bold text-sm'>
                  {GlobalService.uppercaseFirstLetter(t(`pages.signature.${textToShow}`))}
                </p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Signature;
