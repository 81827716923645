import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';

interface LoginSignupSwitchLinkProps {
  type: 'login' | 'signup';
}

const typeToText = {
  login: 'pages.login.noAccount',
  signup: 'pages.signup.haveAnAccount',
};

const typeToLinkText = {
  login: 'pages.signup.register',
  signup: 'pages.signup.login',
};

const typeToHref = {
  login: '/signup',
  signup: '/login',
};

export const LoginSignupSwitchLink: FC<LoginSignupSwitchLinkProps> = ({ type }) => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-row justify-center gap-1 mt-3'>
      <p className='forgot-text pr-2'>
        {GlobalService.uppercaseFirstLetter(t(typeToText[type]))}?{' '}
        <Link to={typeToHref[type]} className='forgot-link'>
          {GlobalService.uppercaseFirstLetter(t(typeToLinkText[type]))}
        </Link>
      </p>
    </div>
  );
};
