import { userRepositoryInstance } from '../../hexagonal-architecture-frontend-base/src/infrastructure/instances/userRepository';
import { userServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/user.service';
import { UserSignData } from '../../ts/interfaces/user';

export const saveUserSignDataService = async (data: UserSignData) => {
  console.log('SAVE SIGN DATA', data)
  const userSignData: UserSignData = (await userServices(userRepositoryInstance).saveUserSignData(data)).Prisma;

  return userSignData;
};
