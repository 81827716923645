import axios from 'axios';
import { ApiResponse, Session } from '../../ts';

interface RegisterParams {
  mobile: string;
  password: string;
  smsCode: string;
}

export const signupService = async ({ mobile, password, smsCode }: RegisterParams) => {
  let response: ApiResponse<Session>;

  try {
    const axiosResponse = await axios.post<ApiResponse<Session>>('/users/register', {
      mobile,
      password,
      code: smsCode,
    });

    response = axiosResponse.data;
  } catch (error: any) {
    throw new Error('errors.unexpectedError');
  }

  if (response.isOk === false) {
    if (response.message.toLowerCase() === 'mobile already registered') {
      throw new Error('modal.verifyMobile.errors.mobileAlreadyRegistered');
    }

    if (response.message.toLowerCase() === 'invalid sms code') {
      throw new Error('modal.verifyMobile.errors.invalidSmsCode');
    }

    throw new Error('errors.unexpectedError');
  }

  return response.data;
};
