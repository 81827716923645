import styles from './help.module.css';
import classNames from 'classnames/bind';
// Components
import { ButtonBase } from '../../components/Buttons/button-base';
import { HelpButton, TopMenu } from '../../components';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// CSS
import '../../styles/Help/Help.css';

// Icons
import { faPhone, faStopwatch, faDisplay, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

// Images
import Callcenter from '../../img/HelpImg/helpLuracare.png';

// Hooks
import { useSettings, useShowSettingsErrorPopup } from '../../hooks';
import { AvailableSetting } from '../../ts/types/settings/available-setting';

import { AVAILABLE_SETTINGS, PHONE_PREFIX } from '../../consts';
import { HelpButtonProps } from '../../ts';

const cx = classNames.bind(styles);

export const Help = () => {
  const { t } = useTranslation();
  const { getSettingKey } = useSettings();
  const { showSingleSettingErrorPopup } = useShowSettingsErrorPopup();
  const textBase = 'pages.help';

  const handleFooterClick = (item: number) => {
    const link1 = getLink(AVAILABLE_SETTINGS.AVISO_LEGAL) ?? null;
    const link2 = getLink(AVAILABLE_SETTINGS.PRIVACIDAD) ?? null;
    const links = [link1, link2];
    const linkValue = links[item];
    if (linkValue) {
      window.open(linkValue, '_blank');
    }
  };

  /***********
   * Helpers *
   ***********/
  const getLink = (key: AvailableSetting): string | undefined => {
    const link = getSettingKey(key);
    if (link === 'settingNotFound') {
      showSingleSettingErrorPopup();
      return '';
    }
    return link;
  };

  const helpButtonsObjects: HelpButtonProps[] = [
    {
      icon: faPhone,
      link: `tel:${PHONE_PREFIX}${getSettingKey(AVAILABLE_SETTINGS.CALL).replaceAll(' ', '')}`,
      text: GlobalService.uppercaseFirstLetter(t(`${textBase}.${AVAILABLE_SETTINGS.CALL}`)),
    },
    {
      icon: faWhatsapp,
      link: getSettingKey(AVAILABLE_SETTINGS.WHATSAPP),
      text: GlobalService.uppercaseFirstLetter(t(`${textBase}.${AVAILABLE_SETTINGS.WHATSAPP}`)),
    },
    {
      icon: faStopwatch,
      link: getSettingKey(AVAILABLE_SETTINGS.SCHEDULE_CALL),
      text: GlobalService.uppercaseFirstLetter(
        t(`${textBase}.${AVAILABLE_SETTINGS.SCHEDULE_CALL}`),
      ),
    },
    {
      icon: faDisplay,
      link: getSettingKey(AVAILABLE_SETTINGS.VIDEO_CALL),
      text: GlobalService.uppercaseFirstLetter(t(`${textBase}.${AVAILABLE_SETTINGS.VIDEO_CALL}`)),
    },
    {
      icon: faEnvelope,
      link: `mailto:${getSettingKey(AVAILABLE_SETTINGS.SEND_EMAIL)}`,
      text: GlobalService.uppercaseFirstLetter(t(`${textBase}.${AVAILABLE_SETTINGS.SEND_EMAIL}`)),
    },
  ];

  /**************
   * JSX Values *
   **************/
  document.title = `LuraCare - ${GlobalService.uppercaseFirstLetter(t(`${textBase}.title`))}`;
  // Icons definitions

  // Footer buttons text definitions
  const footerButtonsTextDefinition = [`${textBase}.legalNotice`, `${textBase}.privacyPolicy`];

  // The help buttons
  const helpButtons = helpButtonsObjects.map((button, index) => (
    <HelpButton key={index} icon={button.icon} link={button.link} text={button.text} />
  ));

  return (
    <div className={cx('main-container')}>
      <TopMenu title={GlobalService.uppercaseFirstLetter(t(`${textBase}.title`))} />

      <div className={cx('inner-container')}>
        <p className={cx('text')}>
          {GlobalService.uppercaseFirstLetter(t(`${textBase}.firstText`))}
        </p>

        <div className={cx('btns-image-container')}>
          <div className={cx('btns-container')}>{helpButtons}</div>

          <div className={cx('img-container')}>
            <img src={Callcenter} className={cx('img')} alt='help image' />
          </div>
        </div>

        <p className={cx('text')}>
          {GlobalService.uppercaseFirstLetter(t(`${textBase}.secondText`))}
        </p>
      </div>

      <div className='flex flex-col sm:flex-row w-full items-center justify-center gap-2'>
        {footerButtonsTextDefinition.map((text: string, index: number) => (
          <ButtonBase
            text={GlobalService.uppercaseFirstLetter(t(text))}
            color='#808080'
            extraClass='underline decoration-solid'
            key={index}
            buttonHeight='25px'
            minHeight='25px'
            paddingX={5}
            paddingY={5}
            onClick={() => handleFooterClick(index)}
          />
        ))}
      </div>
    </div>
  );
};
