import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import Input from '../components/Inputs/InputOld';
import { ButtonBase } from '../components/Buttons/button-base';

// Services
import { authServices } from '../hexagonal-architecture-frontend-base/src/infrastructure/services/auth.services';
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { validate } from '../hexagonal-architecture-frontend-base/src/domain/services/user.service';
import { useTranslation } from 'react-i18next';

// CSS
import '../styles/Signup.css';

// Images
import Image from '../img/forgotPassword.png';
import Logo from '../img/Logos/LogoLandscape.svg';

// Hooks
import { useViewport } from '../hooks/use-viewport';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  /**********
   * States *
   **********/
  const [cancelButtonText, setCancelButtonText] = useState<string>('cancel');
  const [email, setEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [explanationText, setExplanationText] = useState<string>('recoverPasswordText');
  const [okButtonText, setOkButtonText] = useState<string>('sendEmail');
  const [showInput, setShowInput] = useState<boolean>(true);
  const [titleText, setTitleText] = useState<string>('recoverPassword');
  const { viewportWidth } = useViewport();

  /************
   * Handlers *
   ************/
  const handleCancel = () => {
    if (cancelButtonText === 'cancel') {
      navigate('/login');
    } else {
      setTitleText('recoverPassword');
      setCancelButtonText('cancel');
      setOkButtonText('sendEmail');
      setExplanationText('recoverPasswordText');
      setShowInput(true);
    }
  };

  const handleChange = (e: string) => {
    const isValid = validate(e, 'email');
    if (!isValid) {
      setErrorMessage(GlobalService.uppercaseFirstLetter(t('errors.emailInvalid')));
    } else {
      setErrorMessage('');
    }
    setEmail(e);
  };

  const handleSubmit = () => {
    if (okButtonText === 'sendEmail') {
      authServices()
        .forgotPassword({ email })
        .then(() => {
          setTitleText('emailSent');
          setCancelButtonText('sendAgain');
          setOkButtonText('login');
          setExplanationText('okText');
          setShowInput(false);
        });
    } else {
      navigate('/login');
    }
  };

  /**************
   * JSX Values *
   **************/
  const buttonDisabled = !(email && !errorMessage);
  const isScreenBig = viewportWidth > 768;
  const isScreenBigHeight = viewportWidth > 600;
  const cancelButton = (
    <div className='mt-4'>
      <ButtonBase
        text={GlobalService.uppercaseFirstLetter(t(`pages.forgotPassword.${cancelButtonText}`))}
        background='linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'
        color={'white'}
        borderRadius={'50px'}
        onClick={handleCancel}
        buttonWidth='100%'
      />
    </div>
  );

  const userInputs = showInput ? (
    <div>
      <label className='block text-sm font-medium text-gray-700 purple-text'>
        {GlobalService.uppercaseFirstLetter(t('e-mail'))}
      </label>
      <div className='mt-1'>
        <Input
          id='email'
          name='email'
          placeholder={GlobalService.uppercaseFirstLetter(t('e-mail'))}
          type='text'
          value={email}
          customClass='login-input'
          onChange={handleChange}
        />
      </div>
    </div>
  ) : null;

  /*******
   * JSX *
   *******/
  return (
    <div className='relative w-full'>
      <div
        className={`relative ${
          isScreenBigHeight ? 'help-height' : 'help-screen-height-mobile'
        } grid grid-cols-1 lg:grid-cols-2 gap-x-4 3xs:mt-[40px] md:mt-[80px] md:my-8 mx-4 xl:mx-24`}
      >
        {!isScreenBig ? (
          <div className='flex justify-center relative'>
            <img src={Logo} style={{ width: '80%' }} />
          </div>
        ) : null}
        <div className={isScreenBig ? 'form-div' : 'form-div-small'}>
          <div className={isScreenBig ? 'form-container-forgot-password' : 'form-container-small'}>
            <h2 className={`form-title${isScreenBig ? '' : '-small'}`}>
              {GlobalService.uppercaseFirstLetter(t(`pages.forgotPassword.${titleText}`))}
            </h2>
            <p className='recover-password-message form-explanation'>
              {t(`pages.forgotPassword.${explanationText}`)}
            </p>
            {userInputs}
            {errorMessage ? <p className='error-message my-2 mx-4'>{errorMessage}</p> : null}
            <div className='mt-14'>
              <ButtonBase
                text={GlobalService.uppercaseFirstLetter(t(`pages.forgotPassword.${okButtonText}`))}
                background={'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'}
                color={'white'}
                borderRadius={'50px'}
                onClick={handleSubmit}
                buttonWidth='100%'
                disabled={buttonDisabled}
              />
            </div>
            {cancelButton}
          </div>
        </div>

        <div className='hidden left-image lg:flex content-center'>
          <img src={Image} />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
