import styles from './sign-input.module.css';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import Input from '../../Inputs/InputOld';
import { FC } from 'react';

const cx = classNames.bind(styles);

interface SignInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  id: string;
  label: string;
  placeholder?: string;
  type: 'text' | 'hidden';
  value: string;
  onChange?: (value: string) => void;
  errorMessage?: string | null;
}

export const SignInput: FC<SignInputProps> = ({
  id,
  label,
  placeholder = '',
  type,
  value,
  onChange = () => {},
  errorMessage = null,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx('sign-input--container')}>
      <label htmlFor={id} className={cx('sign-input--label')}>
        {GlobalService.uppercaseFirstLetter(t(label), false)}
      </label>

      <Input
        id={id}
        name={id}
        placeholder={GlobalService.uppercaseFirstLetter(t(placeholder), false)}
        type={type}
        value={value}
        customClass={cx('sign-input--input', { disabled })}
        onChange={e => onChange(e)}
        disabled={disabled}
        {...rest}
      />
      {errorMessage != null && errorMessage !== '' ? (
        <p className='error-message my-2 mx-4'>
          {GlobalService.uppercaseFirstLetter(t(errorMessage))}
        </p>
      ) : null}
    </div>
  );
};
