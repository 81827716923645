import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components

import Card from '../components/Cards/Card';
import { Pagination } from '../../src/components/Pagination';
import RegularSpinner from '../components/Spinner/RegularSpinner';
import Title from '../components/Title';
import { TopMenu } from '../components';
import ModalInfo from '../components/Modals/ModalInfo';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { tiendaServices } from '../hexagonal-architecture-frontend-base/src/infrastructure/services/tienda.service';
import { tiendaRepositoryInstance } from '../hexagonal-architecture-frontend-base/src/infrastructure/instances/tiendaRepository';
import { useTranslation } from 'react-i18next';

// Interfaces
import {
  IOrder,
  IOrderItem,
} from '../hexagonal-architecture-frontend-base/src/domain/models/IPago';

// CSS
import '../styles/Notificaciones/Notificaciones.css';

// Images
import LeftArrow from '../img/Notificaciones/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';

// Hooks
import { useViewport } from '../hooks/use-viewport';

const Pedidos = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // States
  const [numberItems] = useState<number>(8);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [pedidosItems, setPedidosItems] = useState<IOrder[]>([]);
  const [status, setStatus] = useState<string>('noncompleted');
  const { viewportWidth } = useViewport();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      tiendaServices(tiendaRepositoryInstance)
        .getPedidosItems(numberItems, currentPage, status)
        .then((data: IOrder[] | string) => {
          if (typeof data !== 'string') {
            setPedidosItems(data);
          } else {
            setPedidosItems([]);
            setModalMessage(data);
            setModalTitle('error');
            setShowModal(true);
          }

          setLoading(false);
        });
      tiendaServices(tiendaRepositoryInstance)
        .getPedidosItemsCount(status)
        .then((count: number | string) => {
          if (typeof count !== 'string') {
            setTotalPages(Math.ceil(count / numberItems));
          } else {
            setTotalPages(0);
            setModalMessage(count);
            setModalTitle('error');
            setShowModal(true);
          }
        });
    }, 1000);
  }, [status]);

  // Handlers
  const handlePagination = (e: number) => {
    setCurrentPage(e);
    setLoading(true);
    tiendaServices(tiendaRepositoryInstance)
      .getPedidosItems(numberItems, currentPage, status)
      .then((data: IOrder[] | string) => {
        if (typeof data !== 'string') {
          setPedidosItems(data);
        } else {
          setPedidosItems([]);
          setModalMessage(data);
          setModalTitle('error');
          setShowModal(true);
        }

        setLoading(false);
      });
  };

  const handleClickModal = () => {
    setModalMessage('');
    setModalTitle('');
    setShowModal(false);
  };

  // JSX Values
  const isScreenBig = viewportWidth > 768;
  const noData = <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>;
  const topMenuProperties: any = {
    title: GlobalService.uppercaseFirstLetter(t(`pages.pagos.${isScreenBig ? 'title' : 'orders'}`)),
    fixed: !isScreenBig,
  };
  if (!isScreenBig) {
    topMenuProperties.leftIcon = LeftArrowSmall;
    topMenuProperties.leftIconLink = '/pagos';
  }

  // Desktop values
  const desktopSubTitle = (
    <>
      <div className='w-1/12'>
        {isScreenBig ? (
          <img
            onClick={() => {
              navigate('/pagos');
            }}
            src={LeftArrow}
            alt='Back'
            className='Notificaciones-Arrow cursor-pointer'
          />
        ) : (
          <img
            onClick={() => {
              navigate('/pagos');
            }}
            src={LeftArrowSmall}
            alt='Back'
            className='my-auto ml-2 cursor-pointer w-[26px] h-[28px]'
          />
        )}
      </div>

      <div className='w-11/12 text-center md:text-left'>
        <Title
          text={GlobalService.uppercaseFirstLetter(t('pages.pagos.orders'))}
          color='black'
          size={'24px'}
        />
      </div>
    </>
  );

  //Functions
  const getPriceFromItems = (item: IOrder): string => {
    let value = 0;

    if (item && item.items && Array.isArray(item.items) && item.items.length > 0) {
      value = item?.items.reduce(
        (accumulator, currentValue) =>
          accumulator +
          Number(currentValue.Products.price.toString().replace(',', '.')) * currentValue.quantity,
        0,
      );
    }
    return value + ' €';
  };

  const getImageFromItems = (item: IOrder): string => {
    let imageUrl = '';

    if (item && item.items && Array.isArray(item.items) && item.items.length > 0) {
      item.items.map((item: IOrderItem) => {
        item.Products.image.map((image: string) => {
          if (image !== '' && imageUrl === '') imageUrl = image;
        });
      });
    }
    return imageUrl;
  };

  const parseDate = (date: string): any => {
    const dateAux = GlobalService.parseDate(date);
    return dateAux.day + '/' + dateAux.month + '/' + dateAux.year;
  };

  const formatedPedidosItems = pedidosItems.length
    ? pedidosItems.map((item: any, index: any) => (
        <Card
          key={index}
          name={item.id}
          width={500}
          height={140}
          numberItems={2}
          padding='1rem'
          borderRadius='10px'
          backgroundColor='#F3F3F6'
          borderShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
          border={`1px solid ${item.selected ? '#E8E8EF' : '#E8E8EF'}`}
          action={() => {
            //   console.log('Card clicked!');
          }}
        >
          <div className='flex flex-col-2'>
            <img src={getImageFromItems(item)} alt='Pedido' className='w-32 min-h-[105px]' />
            <div className='flex flex-col justify-between ml-4 text-black'>
              <div>
                <p className=''>{item?.items[0]?.Products?.name}</p>
                <p className=''>{getPriceFromItems(item)}</p>
              </div>
              <div>
                <p className='text-xs mb-1'>
                  <strong>Estado del pedido: </strong>
                  {item.status}
                </p>
                <p className='text-xs'>
                  <strong>Próxima entrega:</strong> {parseDate(item.lastReceivedAt)}
                </p>
              </div>
            </div>
          </div>
        </Card>
      ))
    : noData;

  const submenuOptions = (
    <div
      className={`relative grid my-4 Personas-Visitas-Menu ${isScreenBig ? '' : 'w-full fixed'}`}
    >
      <div className={`flex flex-row Personas-Title${isScreenBig ? '' : '-small'} `}>
        {status === 'noncompleted' ? (
          <div>
            <p className='cursor-pointer pb-4 ml-2'>Pedidos pendiente</p>
            <div className='Personas-Visitas-Menu-Selected'></div>
          </div>
        ) : (
          <p className='pb-4 cursor-pointer ml-2' onClick={() => setStatus('noncompleted')}>
            Pedidos pendiente
          </p>
        )}
        {status === 'completed' ? (
          <div>
            <p className='ml-10 cursor-pointer pb-4'>Pedidos completo</p>
            <div className='ml-5 Personas-Visitas-Menu-Selected'></div>
          </div>
        ) : (
          <p className='ml-10 pb-4 cursor-pointer' onClick={() => setStatus('completed')}>
            Pedidos completo
          </p>
        )}
      </div>
    </div>
  );

  return (
    <div className='w-full'>
      {isScreenBig ? <TopMenu {...topMenuProperties} /> : null}
      <div
        className={`w-full ${
          isScreenBig ? 'flex flex-col gap-2 pb-6 px-10' : 'mt-8 mb-24 pb-4 px-6'
        }`}
      >
        <div className='flex text-left my-4'>{desktopSubTitle}</div>
        {submenuOptions}
        <div className='pedidos-height grid grid-cols-1 lg:grid-cols-2 auto-rows-min gap-y-4 gap-x-8 w-full'>
          {loading ? <RegularSpinner /> : formatedPedidosItems}
        </div>
        {totalPages > 1 ? (
          <div className=' m-auto'>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onChange={handlePagination}
            />
          </div>
        ) : null}
      </div>
      {showModal ? (
        <ModalInfo
          titleText={modalTitle}
          bodyText={modalMessage}
          buttonText='Cerrar'
          onClickOk={handleClickModal}
        />
      ) : null}
    </div>
  );
};

export default Pedidos;
