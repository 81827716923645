import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './split-payment.module.css';
import { t } from 'i18next';
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import {
  useSelectedPatientValue,
  useSelectedQuoteValue,
  useSessionValue,
  useViewport,
} from '../../hooks';
import {
  useGetDocumentInfo,
  useGetTemplateInfo,
  useShowErrorPopup,
  useSignUrl,
  useUserAddress,
} from '../../hooks';
import LeftArrow from '../../img/PersonaImg/left-arrow.png';
import Title from '../../components/Title';
import { useNavigate, useParams } from 'react-router-dom';
import { Select } from '../../components/selects';
import { useForm } from '@tanstack/react-form';
import { validate } from '../../utils';
import { Input } from '../../components/Inputs/input';
import { Button, TopMenu } from '../../components';
import { testBicValidator } from '../../services';
import { IPatient } from '../../hexagonal-architecture-frontend-base/src/domain/models/IPersona';
import { SignTemplate } from '../../ts';
import AddressBlock from '../../components/AddressBlock';
import RegularSpinner from '../../components/Spinner/RegularSpinner';
import Loader from '../../components/Loaders/Loader';

const cx = classNames.bind(styles);
const DOCUMENT_TEMPLATE_ID = process.env.REACT_APP_DOCUMENT_TEMPLATE_ID_SEPA;

const formatIBAN = (value: string) => {
  return value
    .replace(/\s/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();
};

const SplitPayment = () => {
  const { patientId, reportId } = useParams();
  const { viewportWidth } = useViewport();
  const { selectedPatient } = useSelectedPatientValue();
  const { userInfo } = useSessionValue();
  const { selectedQuote } = useSelectedQuoteValue();
  const navigate = useNavigate();
  const { getTemplateInfo } = useGetTemplateInfo();
  const { getSignUrl, signUrl } = useSignUrl();
  const { getDocumentInfo } = useGetDocumentInfo();
  const { showErrorPopup } = useShowErrorPopup();
  const {
    userAddress,
    getUserAddress,
    isEmptyAndLoading: isAddressEmptyAndLoading,
  } = useUserAddress();

  const dataToHoldRef = useRef({
    consentId: selectedPatient.dealDetails.Archivo_Consentimientos?.length
      ? selectedPatient.dealDetails.Archivo_Consentimientos[0].id
      : '',
    contactConsentId: selectedPatient.Consentimientos?.length
      ? selectedPatient.Consentimientos[0].id
      : '',
    dealId: selectedPatient.dealDetails.id,
    dealName: selectedPatient.dealDetails.Deal_Name,
    docId: signUrl?.docId,
    patientId: selectedPatient.id,
    patientName: selectedPatient.title,
    reqId: signUrl?.reqId,
  });

  dataToHoldRef.current = {
    consentId: selectedPatient.dealDetails.Archivo_Consentimientos?.length
      ? selectedPatient.dealDetails.Archivo_Consentimientos[0].id
      : '',
    contactConsentId: selectedPatient.Consentimientos?.length
      ? selectedPatient.Consentimientos[0].id
      : '',
    dealId: selectedPatient.dealDetails.id,
    dealName: selectedPatient.dealDetails.Deal_Name,
    docId: signUrl?.docId,
    patientId: selectedPatient.id,
    patientName: selectedPatient.title,
    reqId: signUrl?.reqId,
  };

  const [totalAmount] = useState<number>(selectedQuote?.Grand_Total || 0);
  const [numberOfPayments, setNumberOfPayments] = useState<string>(
    t('modal.splitPayment.numberPayments') || '',
  );
  const [numberOfPaymentsOptionsState, setNumberOfPaymentsOptions] = useState<
    { value: string; content: JSX.Element }[]
  >([]);
  const [totalAmountWithFees, setTotalAmountWithFees] = useState<number>(0);
  const [totalAmountMonthly, setTotalAmountMonthly] = useState<number>(0);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [textToShow, setTextToShow] = useState<string>('establishConnection');
  const [getData, setGetData] = useState<boolean>(true);
  const [bicCode, setBicCode] = useState<string>('');
  const [isBitCodeValid, setIsBitCodeValid] = useState<boolean>(true);
  const [showDocmunet, setShowDocument] = useState<boolean>(true);
  const [ searchiingBic, setSearchiingBic ] = useState<boolean>(false);

  const textBase = 'pages.informeDetallado';
  const tutorName = userInfo?.name;
  let timer: any;

  const topMenuProperties: any = {
    title: `${GlobalService.uppercaseFirstLetter(t(`${textBase}.mainTitle`))}: ${
      selectedPatient.title
    }`,
    user: tutorName,
  };

  const isScreenBig = viewportWidth > 768;

  useEffect(() => {
    let options: { value: string; content: JSX.Element }[] = [];
    if (totalAmount >= 300 && totalAmount < 600) {
      options = [
        { value: '1', content: <span>1</span> },
        { value: '2', content: <span>2</span> },
        { value: '3', content: <span>3</span> },
      ];
    } else if (totalAmount >= 600 && totalAmount < 1000) {
      options = [
        { value: '1', content: <span>1</span> },
        { value: '2', content: <span>2</span> },
        { value: '3', content: <span>3</span> },
        { value: '4', content: <span>4</span> },
        { value: '5', content: <span>5</span> },
        { value: '6', content: <span>6</span> },
      ];
    } else if (totalAmount >= 1000) {
      options = [
        { value: '1', content: <span>1</span> },
        { value: '2', content: <span>2</span> },
        { value: '3', content: <span>3</span> },
        { value: '4', content: <span>4</span> },
        { value: '5', content: <span>5</span> },
        { value: '6', content: <span>6</span> },
        { value: '7', content: <span>7</span> },
        { value: '8', content: <span>8</span> },
        { value: '9', content: <span>9</span> },
        { value: '10', content: <span>10</span> },
        { value: '11', content: <span>11</span> },
        { value: '12', content: <span>12</span> },
      ];
    }
    setNumberOfPaymentsOptions(options);
  }, [totalAmount]);

  useEffect(() => {
    setTotalAmountWithFees(38 + totalAmount);
  }, [totalAmount]);

  useEffect(() => {
    if (numberOfPayments) {
      setTotalAmountMonthly(
        parseFloat((totalAmountWithFees / parseInt(numberOfPayments)).toFixed(2)),
      );
    }
  }, [numberOfPayments, totalAmountWithFees]);

  const handleSetBic = (bic: string) => {
    setBicCode(bic);
  };

  const stopInterval = () => {
    clearInterval(timer);
    timer = null;
  };

  const getDocumentSignState = () => {
    if (dataToHoldRef.current.docId == null || dataToHoldRef.current.reqId == null) {
      return;
    }

    getDocumentInfo(
      { reqId: dataToHoldRef.current.reqId },
      {
        onSuccess: documentInfo => {
          if (documentInfo.requests.request_status !== 'completed' || !getData) return;

          setGetData(false);
          stopInterval();
          setTextToShow('savingDoc');
          navigate(`/informeDetallado/${patientId}/${reportId}`);
        },
      },
    );
  };

  const fetchData = async (patient: IPatient, iban: string, bicCode: string) => {
    getTemplateInfo(
      {
        docId: DOCUMENT_TEMPLATE_ID,
      },
      {
        onError: () => {
          showErrorPopup('errors.signRequestFailed');
        },
        onSuccess: async templateData => {
          const dataToSend: { templates: SignTemplate } = {
            templates: {
              field_data: {
                field_text_data: {
                  ['Tutor Name']: userInfo?.name ?? '',
                  ['Tutor DNI']: userInfo?.dni ?? '',
                  ['Patient Name']: patient.title,
                  ['Residency Adress']: userAddress?.Direcci_n,
                  ['Country']: userAddress?.Pa_s,
                  ['Cuenta IBAN']: iban,
                  ['Swift BIC']: bicCode,
                  ['Ammount']: `${totalAmountMonthly.toString()}€`,
                  ['Payments']: numberOfPayments.toString(),
                  ['PC']: userAddress?.Codigo_postal,
                },
                field_boolean_data: {
                  ['Pago único']: parseInt(numberOfPayments) > 1 ? false : true,
                  ['Pago recurrente']: parseInt(numberOfPayments) > 1 ? true : false,
                },
              },
              actions: [
                {
                  ...templateData.templates.actions[0],
                  recipient_name: selectedPatient.title,
                  recipient_email: selectedPatient.email,
                  verify_recipient: false,
                  private_notes: '',
                  action_id: templateData.templates.actions[0].action_id,
                  is_embedded: true,
                },
              ],
              notes: '',
            },
          };
          delete dataToSend.templates.actions?.[0].fields;
          getSignUrl(
            {
              data: dataToSend,
              templateId: DOCUMENT_TEMPLATE_ID,
            },
            {
              onError: () => showErrorPopup('errors.signRequestFailed'),
              onSuccess: () => {
                setShowDocument(false);
                if (!timer) {
                  timer = setInterval(getDocumentSignState, 2500);
                }
              },
            },
          ); 
        },
      },
    );
  };

  const form = useForm({
    defaultValues: {
      accountName: '',
      address: '',
      iban: '',
      bic: '',
      country: '',
    },
    onSubmit: async values => {
      fetchData(selectedPatient, values.value.iban, bicCode);
    },
  });

  return (
    <div className={cx('main-container')}>
      {isScreenBig ? (
        <div className={cx('top-menu-container')}>
          <TopMenu {...topMenuProperties} />
        </div>
      ) : (
        <TopMenu {...topMenuProperties} />
      )}
      {showDocmunet && (
        <div className={cx('body-container')}>
          {isScreenBig && <div className='flex text-left mb-6'>
            <img
              onClick={() => navigate(-1)}
              src={LeftArrow}
              alt='Back'
              className='Notificaciones-Arrow cursor-pointer'
            />
            <Title
              text={`${GlobalService.uppercaseFirstLetter(t(`pages.paymentMethod.split`))}:`}
              color='black'
              size={'24px'}
              margin={'0px 0px 0px 30px'}
            />
          </div>}
          <div className={cx('body-container-main')}>
            <div className={cx('form-container', 'gap-y-2 mx-2 bg-white')}>
              <p>{`${GlobalService.uppercaseFirstLetter(
                t('modal.splitPayment.totalAmount'),
              )} ${totalAmount}€. ${GlobalService.uppercaseFirstLetter(
                t('modal.splitPayment.choosePayment'),
              )}`}</p>
              <p>{t(`modal.splitPayment.selectnumeberpayments`)}</p>
              <Select
                label={numberOfPayments.toString()}
                setValue={setNumberOfPayments}
                options={numberOfPaymentsOptionsState}
              />
              <p>
                <span className={cx('gestion--text')}>
                  {GlobalService.uppercaseFirstLetter(t('modal.splitPayment.managementfees'))}
                </span>
                : 38€
              </p>
              <p>
                <span className={cx('gestion--text')}>
                  {GlobalService.uppercaseFirstLetter(t('modal.splitPayment.totalAmountWithFees'))}
                </span>{' '}
                {totalAmountWithFees}€
              </p>
              <p>
                <span className={cx('gestion--text')}>
                  {GlobalService.uppercaseFirstLetter(
                    t('modal.splitPayment.totalAmountWithFeesMonthly'),
                  )}{' '}
                </span>
                {totalAmountMonthly ? totalAmountMonthly : totalAmountWithFees}€
              </p>
            </div>
            <div className={cx('form-container', 'flex flex-col gap-y-2 mx-2 bg-white')}>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  form.handleSubmit();
                }}
                className={cx('flex flex-col gap-2 mx-2')}
              >
                <form.Field
                  name={'accountName'}
                  validators={{
                    onChange: value => (validate(value.value, 'name') ? undefined : 'error.'),
                  }}
                  // eslint-disable-next-line react/no-children-prop
                  children={field => (
                    <Input
                      id={field.name}
                      label={GlobalService.uppercaseFirstLetter(
                        t('modal.splitPayment.accountHolder'),
                      )}
                      placeholder=''
                      type={'text'}
                      value={field.state.value}
                      onChange={field.handleChange}
                      onBlur={field.handleBlur}
                      errorMessage={field.state.meta.errors.join(', ')}
                    />
                  )}
                />
                <form.Field
                  name={'iban'}
                  validators={{
                    onChange: value => {

                      return validate(value.value.replace(/\s+/g,''), 'iban') ? undefined : GlobalService.uppercaseFirstLetter(t('modal.splitPayment.errorIban'));
                    },
                    onChangeAsync: async val => {
                      //cambiar para cerar estados que hagan las llamada del bicCode y si es valido
                      setSearchiingBic(true);
                      if (bicCode === '') {
                        const bicCode = await testBicValidator(val.value.slice(5, 9));
                        setBicCode(bicCode);
                        if (bicCode === 'DontExistInDatabase') {
                          setIsBitCodeValid(false);
                          return t('modal.splitPayment.bicDontExist');
                        }
                      }
                      return;
                    },
                    onChangeAsyncDebounceMs: 2000,
                    onChangeListenTo: ['iban', 'bic'],
                  }}
                  // eslint-disable-next-line react/no-children-prop
                  children={field => (
                    <Input
                      id={field.name}
                      label={GlobalService.uppercaseFirstLetter(t('modal.splitPayment.iban'))}
                      placeholder=''
                      type={'text'}
                      value={formatIBAN(field.state.value.toLocaleUpperCase())}
                      onChange={field.handleChange}
                      onBlur={field.handleBlur}
                      errorMessage={field.state.meta.errors.join(', ')}
                    />
                  )}
                />
                {!isBitCodeValid && (
                  <form.Field
                    name={'bic'}
                    validators={{
                      onChange: value => {
                        handleSetBic(value.value);
                        return undefined;
                      },
                      onChangeListenTo: ['iban', 'bic'],
                    }}
                    // eslint-disable-next-line react/no-children-prop
                    children={field => (
                      <Input
                        id={field.name}
                        label={GlobalService.uppercaseFirstLetter(t('modal.splitPayment.bic'))}
                        placeholder=''
                        type={'text'}
                        value={bicCode === 'DontExistInDatabase' ? '' : bicCode}
                        onChange={field.handleChange}
                        onBlur={field.handleBlur}
                        errorMessage={field.state.meta.errors.join(', ')}
                      />
                    )}
                  />
                )}
                <form.Field
                  name={'address'}
                  validators={{
                    onChange: ({ value }) => (validate(value, 'Direcci_n') ? undefined : 'error.'),
                  }}
                  // eslint-disable-next-line react/no-children-prop
                  children={field => <AddressBlock isScreenBig={isScreenBig} title={'Direccion'} />}
                />
                <form.Subscribe
                  // @ts-ignore
                  selector={state => [state.canSubmit, state.isSubmitting]}
                  // @ts-ignore
                  // eslint-disable-next-line react/no-children-prop
                  children={([canSubmit, isSubmitting]) => (
                    <Button
                      styleType='primary'
                      disabled={canSubmit && numberOfPayments === t('modal.splitPayment.numberPayments')}
                      fullWidth={true}
                      type='submit'
                      isLoading={isSubmitting}
                    >
                      {isSubmitting ? (
                        <Loader size='23px' border='3px' />
                      ) : (
                        GlobalService.uppercaseFirstLetter(t('modal.splitPayment.continue'))
                      )}
                    </Button>
                  )}
                />
              </form>
            </div>
          </div>
        </div>
      )}
      {!showDocmunet && (
        <div className='w-full h-full flex flex-col gap-8 items-center justify-around'>
          {showSpinner ? <RegularSpinner /> : null}
          {signUrl?.url ? (
            <iframe
              title='signature-frame'
              src={signUrl.url}
              style={{ width: '100vh', height: '758px' }}
              onLoad={() => setShowSpinner(false)}
            ></iframe>
          ) : (
            <p className='text-center max-w-xl font-bold'>
              {GlobalService.uppercaseFirstLetter(t(`pages.signature.${textToShow}`))}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default SplitPayment;
