import { FC, useEffect } from 'react';
import RegularSpinner from './Spinner/RegularSpinner';
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { useShowEditAddressPopup, useUserAddress } from '../hooks';
import { Button } from './Buttons';

interface Props {
  isScreenBig: boolean;
  title: string;
}

const AddressBlock: FC<Props> = ({ isScreenBig, title }) => {
  const { t } = useTranslation();
  const {
    userAddress,
    getUserAddress,
    isEmptyAndLoading: isAddressEmptyAndLoading,
  } = useUserAddress();
  const { showEditAddressPopup } = useShowEditAddressPopup();

  useEffect(() => {
    getUserAddress();
  }, []);

  return (
    <div
      className={`direction-container flex justify-between mb-6 ${!isScreenBig ? 'flex-col' : ''}`}
    >
      {isAddressEmptyAndLoading ? (
        <RegularSpinner />
      ) : (
        <>
          <div className={`${isScreenBig ? '' : 'text-sm'}`}>
            <p> {title}: </p>
            <p className='direction-text mt-2 '>
              {userAddress?.Direcci_n}
              {userAddress?.Codigo_postal ? ',' : ''} {userAddress?.Codigo_postal}
              {userAddress?.Ciudad ? ',' : ''} {userAddress?.Ciudad}
              {userAddress?.Pa_s ? ',' : ''} {userAddress?.Pa_s}
            </p>
          </div>

          <div className=''>
            <Button styleType='secondary' onClick={showEditAddressPopup}>
              {GlobalService.uppercaseFirstLetter(t('edit'))}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default AddressBlock;
