import styles from './help-button.module.css';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../button';
import { faDisplay, faUser } from '@fortawesome/free-solid-svg-icons';
import { FC, useRef } from 'react';
import { HelpButtonProps } from '../../../ts';

const cx = classNames.bind(styles);

export const HelpButton: FC<HelpButtonProps> = ({ icon, link, text }) => {
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  const handleClick = (ref: HTMLAnchorElement | null) => {
    if (ref == null) return;

    ref.click();
  };

  return (
    <Button onClick={() => handleClick(linkRef.current)} fullWidth={true} styleType='tertiary'>
      <a
        className={cx('button-content')}
        href={link}
        target={link.includes('http') ? '_blank' : '_self'}
        rel='noreferrer noopener'
        ref={linkRef}
      >
        {icon === faDisplay ? (
          <span className='fa-layers fa-fw'>
            <FontAwesomeIcon color='#E96E81' icon={faDisplay} />
            <FontAwesomeIcon color='#E96E81' icon={faUser} size='xs' transform='shrink-4' />
          </span>
        ) : (
          <FontAwesomeIcon color='#E96E81' icon={icon} />
        )}
        {text}
      </a>
    </Button>
  );
};
