import styles from './signup.module.css';
import classNames from 'classnames/bind';
import { LoginSignupLayout, SignupForm0, MobileVerificationForm } from '../../components';
import { useState } from 'react';
import { useMobileVerificationIntervals, useSetSession, useSignup } from '../../hooks';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

export const Signup = () => {
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  useMobileVerificationIntervals();
  const { signupAsync, error: signupError, clear: clearSignup } = useSignup();
  const { setSession } = useSetSession();
  const navigate = useNavigate();

  const [currentForm, setCurrentForm] = useState(0);

  const moveToForm0 = () => {
    setCurrentForm(0);
  };

  const moveToForm1 = () => {
    setCurrentForm(1);
  };

  const handleRegister = async (smsCode: string) => {
    try {
      await signupAsync(
        { mobile: mobile, password, smsCode: smsCode },
        {
          onSuccess: response => {
            console.log('response', response);
            setSession(response.auth, response.userInfo);
            navigate('/login');
          },
          onError: error => {
            console.error('error', error);
          },
        },
      );
    } catch (error: any) {
      // console.error('error', error);
    }
  };

  return (
    <LoginSignupLayout type={currentForm === 0 ? 'signup' : 'signupVerifyMobile'}>
      {currentForm === 0 ? (
        <SignupForm0
          initialValues={{ mobile, password, passwordConfirmation }}
          setInitialValues={{ setMobile, setPassword, setPasswordConfirmation }}
          handleSubmit={moveToForm1}
        />
      ) : (
        <MobileVerificationForm
          mobile={mobile}
          goBack={moveToForm0}
          handleSubmit={handleRegister}
          clearSubmitError={clearSignup}
          submitError={signupError?.message}
        />
      )}
    </LoginSignupLayout>
  );
};
