import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { ButtonBase } from '../components/Buttons/button-base';
import ModalInfo from '../components/Modals/ModalInfo';
import ModalPayment from '../components/Modals/ModalPayment';
import ModalTransferencia from '../components/Modals/ModalTransferencia';
import ModalYesNo from '../components/Modals/ModalYesNo';
import { TopMenu } from '../components';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { tiendaRepositoryInstance } from '../hexagonal-architecture-frontend-base/src/infrastructure/instances/tiendaRepository';
import { tiendaServices } from '../hexagonal-architecture-frontend-base/src/infrastructure/services/tienda.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import { IAddressData } from '../hexagonal-architecture-frontend-base/src/domain/models/IAddressItem';

import { IUserDataPayment } from '../hexagonal-architecture-frontend-base/src/domain/models/IPago';

// CSS
import '../styles/Help/Help.css';

// Images
import Callcenter from '../img/HelpImg/helpLuracare.png';

// Hooks
import { useViewport } from '../hooks/use-viewport';
import {
  useSelectedPatient,
  useSelectedQuoteValue,
  useSessionValue,
  useSetSelectedPatient,
  useSetSelectedQuote,
  useSettings,
  useSetUrlToReturn,
  useUpdateDealAfterPayment,
} from '../hooks';
import { AVAILABLE_SETTINGS } from '../consts/settings/available-settings';
import { PaymentIntent, PaymentIntentResult } from '@stripe/stripe-js';
import { is } from '@babel/types';

const PaymentMethod = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId, reportId } = useParams();
  const textBase = 'pages.paymentMethod';
  const total = localStorage.getItem('tot');
  const { userInfo } = useSessionValue();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();
  const { getSettingKey } = useSettings();
  const { updateDealAfterPayment } = useUpdateDealAfterPayment();
  const { setUrlToReturn } = useSetUrlToReturn();
  const { setSelectedQuote, removeSelectedQuote } = useSetSelectedQuote();
  const { removeSelectedPatient } = useSetSelectedPatient();

  const tutorName = userInfo?.name;
  const dataToHold = {
    consentId: selectedPatient.dealDetails.Archivo_Consentimientos?.length
      ? selectedPatient.dealDetails.Archivo_Consentimientos[0].id
      : '',
    contactConsentId: selectedPatient.Consentimientos?.length
      ? selectedPatient.Consentimientos[0].id
      : '',
    dealId: selectedPatient.dealDetails.id,
    dealName: selectedPatient.dealDetails.Deal_Name,
    docId: '',
    patientId: selectedPatient.id,
    patientName: selectedPatient.title,
    reqId: '',
  };

  /**********
   * States *
   **********/
  const [address, setAddress] = useState<IAddressData>();
  const [infoText, setInfoText] = useState<string>('');
  const [isMailSent, setIsMailSended] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showFinancModal, setShowFinancModal] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [showTransfModal, setShowTransfModal] = useState<boolean>(false);
  const [showVisaModal, setShowVisaModal] = useState<boolean>(false);
  const { viewportWidth } = useViewport();

  // TODO: Make sure that the user fields are filled here
  const userData =
    userInfo == null
      ? undefined
      : {
          id: userInfo.id,
          name: userInfo.name!,
          email: userInfo.email!,
          phone: userInfo.phone,
          stripeId: userInfo.stripeId!,
        };

  /*********
   * Hooks *
   *********/
  useEffect(() => {
    setSelectedPatient(patientId as string);
  }, [patientId]);

  useEffect(() => {
    fetchAddressData();
  }, [selectedPatient]);

  useEffect(() => {
    if (reportId == null) {
      removeSelectedQuote();
      return;
    }
    setSelectedQuote(reportId);
  }, [reportId]);

  /************
   * Handlers *
   ************/
  const handleOnClickEmail = () => {
    if (!isMailSent) {
      const salut = `${GlobalService.uppercaseFirstLetter(t('modal.transferencia.salut'))} ${
        dataToHold.patientName
      }`;
      const subject = `${GlobalService.uppercaseFirstLetter(t('modal.transferencia.subject'))} ${
        dataToHold.patientName
      }`;
      const body = `
      <div>
          <div>
              <p style="font-weight:700;">${GlobalService.uppercaseFirstLetter(
                t('modal.transferencia.accountNum'),
              )}</p>
              <p>${getSettingKey(AVAILABLE_SETTINGS.TRANSFER_ACC)}</p>
          </div>
          <div>
              <p style="font-weight:700;">${GlobalService.uppercaseFirstLetter(
                t('modal.transferencia.amount'),
              )}</p>
              <p>${total}</p>
          </div>
          <div>
              <p style="font-weight:700;">${GlobalService.uppercaseFirstLetter(
                t('modal.transferencia.concept'),
              )}</p>
              <p>${selectedPatient.title}</p>
          </div>
          <div>
              <p>${GlobalService.uppercaseFirstLetter(
                t('modal.transferencia.bodyText'),
              )} ${mail}</p>
          </div>
          <div>
              <p>${GlobalService.uppercaseFirstLetter(t('modal.transferencia.thanks'))}</p>
          </div>
      </div>`;
      const details = {
        body,
        mail: userInfo?.email ?? '',
        subject,
        salut,
      };
      tiendaServices(tiendaRepositoryInstance)
        .sendTransfMail(details)
        .then(() => {
          setIsMailSended(true);
        });
    }
  };

  const handleOnClickSchedule = () => {
    setShowFinancModal(false);
    const link = getSettingKey(AVAILABLE_SETTINGS.SCHEDULE_CALL);
    window.open(link, '_self');
  };

  const handleNagitaePaymentMethod = (key: string) => {
    navigate(`/informeDetallado/${patientId}/${reportId}/paymentmethod/${key}payment`);
  };

  const handleOnClickSelectPaymentMethod = (key: string) => {
    if (total) {
      switch (key) {
        case 'visa':
          handleNagitaePaymentMethod(key);
          break;
        case 'transfer':
          handleNagitaePaymentMethod(key);
          break;
        case 'split':
          handleNagitaePaymentMethod(key);
          break;
      }
    }
  };

  const handleOnCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const handleOnCloseFinancModal = () => {
    setShowFinancModal(false);
  };

  const handleOnCloseInfoModal = () => {
    setShowInfoModal(false);
    finishPayment();
  };

  const handleOnCloseTransfModal = () => {
    setShowTransfModal(false);
  };

  const handleOnCloseVisaModal = () => {
    setShowVisaModal(false);
  };

  const handleOnVisaResult = async (paymentResult: PaymentIntent | string) => {
    if (typeof paymentResult === 'string') {
      setErrorText('paymentConfirmationError');
      setShowErrorModal(true);
      return;
    }

    setInfoText('pages.tienda.successfulPayment');
    setShowVisaModal(false);

    if (reportId && selectedPatient.title) {
      updateDealAfterPayment({
        DealId: selectedPatient.dealDetails.id,
        Deal_Name: selectedPatient.dealDetails.Deal_Name,
      });
    }

    setUrlToReturn(`/informes/${patientId}`);
    setShowInfoModal(true);
  };

  /***********
   * Helpers *
   ***********/
  const fetchAddressData = async () => {
    tiendaServices(tiendaRepositoryInstance)
      .getBasketAddress('addressenviamentcomanda')
      .then(addresses => {
        if (typeof addresses !== 'string' && addresses?.data && addresses?.data.length) {
          const addressAux = addresses.data[0];
          setAddress(addressAux);
          setAddress({ ...addressAux, Direcci_n: addressAux.Direcci_n || '' });
          setAddress({ ...addressAux, Codigo_postal: addressAux.Codigo_postal || '' });
          setAddress({ ...addressAux, Ciudad: addressAux.Ciudad || '' });
          setAddress({ ...addressAux, Pa_s: addressAux.Pa_s || '' });
          setAddress({ ...addressAux, Name: addressAux.Name || 'Principal' });
        }
      })
      .catch(() => {
        setErrorText('genericError');
        setShowErrorModal(true);
      });
  };

  const finishPayment = () => {
    localStorage.removeItem('tot');
    removeSelectedPatient();
    removeSelectedQuote();
    navigate(`/informedetallado/${patientId}/${reportId}`);
  };

  /**************
   * JSX Values *
   **************/
  document.title = `LuraCare - ${GlobalService.uppercaseFirstLetter(
    t(`${textBase}.selectPaymentMethod`),
  )}`;
  const isScreenBig = viewportWidth > 768;
  const isScreenBigHeight = viewportWidth > 600;
  const isScreenTiny = viewportWidth < 321;

  // Buttons definitions
  const buttonsDefinition: string[] = ['visa', 'transfer', 'split'];
  const isFinancAvailable = (button: string) => {
    return total && button === 'split' ? parseInt(total.replace('.','')) >= 300 : true;
  };

  // Los botones
  const helpButtons = buttonsDefinition.map((button, index) => (
    <div key={index} className='mt-4'>
      {(button !== 'split' || isFinancAvailable(button)) && (
        <ButtonBase
          background='#FFFFFF'
          border='1px solid rgb(40, 51, 125)'
          borderRadius='50px'
          buttonWidth='100%'
          color='black'
          extraClass='mt-3'
          key={index}
          minHeight={'43px'}
          onClick={() => handleOnClickSelectPaymentMethod(buttonsDefinition[index])}
          text={GlobalService.uppercaseFirstLetter(t(`${textBase}.${button === 'split' && !isScreenBig ?  button + 'Short' : button}`))}
          textPosition='center'
        />
      )}
    </div>
  ));

  let mail = getSettingKey(AVAILABLE_SETTINGS.DOCUMENTS);
  mail = mail.substring(mail.indexOf(':') + 1);

  /**********
   * Modals *
   **********/
  // Error modal
  const modalError = (
    <ModalInfo
      bodyText={`errors.${errorText}`}
      onClickOk={handleOnCloseErrorModal}
      showXCloseButton={false}
      titleText={'error'}
    />
  );

  // Financ Modal
  const modalFinanc = (
    <ModalYesNo
      buttonNoText='cancel'
      buttonYesText='modal.paymentMethod.bookCall'
      bodyQuestion='modal.paymentMethod.bodyQuestion'
      bodyText='modal.paymentMethod.bodyText'
      hideQuestionSign={true}
      highlightYesButton={true}
      onClickYes={handleOnClickSchedule}
      onClickNo={handleOnCloseFinancModal}
    />
  );

  // Info Modal
  const modalInfo = (
    <ModalInfo bodyText={infoText} buttonText={'close'} onClickOk={handleOnCloseInfoModal} />
  );

  // Transfer modal
  const modalTransf = (
    <ModalTransferencia
      account={getSettingKey(AVAILABLE_SETTINGS.TRANSFER_ACC)}
      amount={total as string}
      mail={mail}
      patientName={selectedPatient.title}
      onClickNo={handleOnCloseTransfModal}
      onClickYes={handleOnClickEmail}
    />
  );

  // Visa Modal
  const modalVisa = (
    <ModalPayment
      onClose={handleOnCloseVisaModal}
      userData={userData}
      addressData={address}
      totalAmount={parseFloat(total || '')}
      handlePaymentResult={handleOnVisaResult}
      isLoading={false}
      service={selectedPatient.dealDetails.id}
    />
  );

  /*******
   * JSX *
   *******/
  return (
    <div className='relative w-full'>
      <TopMenu
        title={GlobalService.uppercaseFirstLetter(t(`${textBase}.title`))}
        userName={tutorName}
      />
      <div
        className={`relative ${
          isScreenBigHeight ? 'help-height' : 'help-height-mobile'
        } grid grid-cols-1 lg:grid-cols-2 gap-x-10 mt-[80px] md:my-8 mx-8 xl:mx-24`}
      >
        <div className={`3xs:mt-14 md:mt-20 ${isScreenTiny ? 'mt-14' : ''}`}>
          <p
            className={`2xs:Help-Title-1-small 2xs:text-center 2xs:mb-2 md:Help-Title-1 md:mb-8 break-words whitespace-normal ${
              isScreenBig ? 'Help-Title-1' : ''
            }`}
            style={{ display: 'block', overflowWrap: 'break-word', maxWidth: '100%', lineClamp: 2, WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
          >
            {GlobalService.uppercaseFirstLetter(t(`${textBase}.selectPaymentMethod`))}
          </p>
            {helpButtons}
          </div>
        <div className='w-[497px] h-[300px] mt-20 xl:m-20 mb-24 hidden lg:flex'>
          <img src={Callcenter} width='100%' alt='help image' />
        </div>
      </div>
      {showErrorModal ? modalError : null}
      {showFinancModal ? modalFinanc : null}
      {showInfoModal ? modalInfo : null}
      {showTransfModal ? modalTransf : null}
      {showVisaModal ? modalVisa : null}
    </div>
  );
};

export default PaymentMethod;
