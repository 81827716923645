import { useAtomValue } from 'jotai';
import { saveUserSignDataAtom } from '../../store/global/atoms';

export const useUserSignData = () => {
  const userSignData = useAtomValue(saveUserSignDataAtom);

  return {
    userSignDataData: userSignData.data,
    userSignData: userSignData.mutate,
    userSignDataAsync: userSignData.mutateAsync,
    isLoading: userSignData.isPending,
    error: userSignData.error,
  };
};
 