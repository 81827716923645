import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import RegularSpinner from '../components/Spinner/RegularSpinner';
import Title from '../components/Title';
import { TopMenu } from '../components';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import { IPatient } from '../hexagonal-architecture-frontend-base/src/domain/models/IPersona';

// Images
import LeftArrow from '../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';
import AudiologiaGrey from '../img/PersonaImg/audiologia-grey.png';
import AudiologiaRed from '../img/PersonaImg/audiologia-grey.png';
import DentistaGrey from '../img/PersonaImg/dentista-red.png';
import DentistaRed from '../img/PersonaImg/dentista-red.png';
import OpticaGrey from '../img/PersonaImg/optica-grey.png';
import OpticaRed from '../img/PersonaImg/optica-grey.png';

// CSS
import '../styles/Personas/Personas.css';

// Hooks
import { useViewport } from '../hooks/use-viewport';
import { usePatientsValue, useSelectedPatient } from '../hooks';

interface IDocs {
  avisos: boolean;
  enabled: boolean;
  title: string;
}

const DatosPersonaYServicio = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const comesFromAllPatient: boolean = localStorage.getItem('AllPatients') ? true : false;
  const imgToShow = [
    DentistaGrey,
    DentistaRed,
    AudiologiaGrey,
    AudiologiaRed,
    OpticaGrey,
    OpticaRed,
  ];

  /**********
   * States *
   **********/
  const { patients, isLoading: isPatientsLoading } = usePatientsValue();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();
  const [documentos, setDocumentos] = useState<any>([]);
  const [isThisLoading, setIsThisLoading] = useState<boolean>(true);
  const { viewportWidth } = useViewport();

  const isDocumentosLoading = isPatientsLoading || isThisLoading;

  /*********
   * Hooks *
   *********/
  useEffect(() => {
    if (patients.length === 0) return;
    setIsThisLoading(true);
    // Estableixo el pacient actiu
    setSelectedPatient(patientId as string);
  }, [patients]);

  useEffect(() => {
    setDocumentos(createDocumentos(selectedPatient));
    setIsThisLoading(false);
  }, [selectedPatient]);

  /************
   * Handlers *
   ************/
  const handleLinkClick = (id: number) => {
    if (documentos[id].enabled) {
      navigate(`/datosDocumentos/${patientId}/${id + 1}`);
    }
  };

  const handleOnClickBack = () => {
    const from = localStorage.getItem('AllPatients');
    if (from === 'true') {
      navigate('/allPatients');
    } else {
      navigate('/personas');
    }
  };

  /***********
   * Helpers *
   ***********/
  const createDocumentos = (patient: IPatient): IDocs[] => {
    return [
      {
        avisos: !!patient.pendingVisits || !!patient.pendingReports || !!patient.pendingDocs,
        enabled: true,
        title: 'pages.documentos.dentist',
      },
      // {
      //   avisos: false,
      //   enabled: false,
      //   title: 'pages.documentos.audiology'
      // },
      // {
      //   avisos: false,
      //   enabled: false,
      //   title: 'pages.documentos.optic'
      // }
    ];
  };

  /******************************
   * JSX values (common values) *
   ******************************/
  const isScreenBig = viewportWidth > 768;

  const fieldsDefinition = [
    {
      label: 'fullName',
      value: selectedPatient.title,
      field: 'name',
      // onChange: (e: string) => setSelectedPatient(patient => ({ ...patient, title: e }))
    },
    {
      label: 'residence',
      value: selectedPatient.residence,
      field: 'residence',
      // onChange: (e: string) => setSelectedPatient(patient => ({ ...patient, residence: e }))
    },
    {
      label: 'extras',
      value: selectedPatient.pendingDocs
        ? GlobalService.uppercaseFirstLetter(t('pages.personas.pendingProcedures'))
        : GlobalService.uppercaseFirstLetter(t('pages.documentos.noPendingProcedures')),
      field: 'pendingDocs',
      // onChange: (e: boolean) => setSelectedPatient(patient => ({ ...patient, pendingDocs: e }))
    },
  ];

  const formattedDocuments = documentos.map((item: any, index: number) => (
    <div
      key={index}
      className={`m-auto Personas-Documentos${
        isScreenBig
          ? ''
          : index === documentos.length - 1 && !isScreenBig
          ? '-Small-End flex flex-row'
          : '-Small flex flex-row'
      } Personas-Documentos-${
        (selectedPatient.pendingDocs ||
          selectedPatient.pendingReports ||
          selectedPatient.pendingVisits) &&
        item.enabled
          ? 'Con-Aviso'
          : 'Sin-Aviso'
      }`}
      onClick={() => handleLinkClick(index)}
    >
      <img
        className={`Personas-Documentos-Imagen-${isScreenBig ? 'Big' : 'Small'}`}
        src={imgToShow[index * 2 + (item.avisos ? 0 : 1)]}
      ></img>
      <p className='Personas-Documentos-Texto'>
        {GlobalService.uppercaseFirstLetter(t(item.title))}
      </p>
      {selectedPatient.pendingDocs && item.enabled ? (
        <div
          className={`absolute ${
            isScreenBig ? 'top-4' : 'top-10'
          } right-4 w-3 h-3 rounded-full bg-red-600`}
        ></div>
      ) : null}
    </div>
  ));

  const formattedPatients = fieldsDefinition.map((field, index) => {
    return (
      <div key={index} className={`ml-2 ${index ? 'mt-2' : ''}`}>
        <p
          className={`Personas-Label ${!index ? 'pt-2' : ''}`}
        >{`${GlobalService.uppercaseFirstLetter(t(field.label))}:`}</p>
        <p className='Personas-Info'>{field.value}</p>
      </div>
    );
  });

  const noData = <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>;

  const patientTitle = (
    <div className={`flex text-left`}>
      <Title
        text={`${GlobalService.uppercaseFirstLetter(t('patientInfo'))}:`}
        color='black'
        size={isScreenBig ? '24px' : '18px'}
        margin='0px'
      />
    </div>
  );

  /******************
   * Desktop Values *
   ******************/
  const desktopPageTitle = `${GlobalService.uppercaseFirstLetter(t('historyOf'))}:`;

  const desktopDocuments = isDocumentosLoading ? (
    <RegularSpinner />
  ) : (
    <div className='grid grid-cols-3 gap-5 mt-6'>
      {documentos.length ? formattedDocuments : noData}
    </div>
  );

  const desktopDocumentsTitle = (
    <div>
      <div className='flex text-left'>
        <img
          onClick={handleOnClickBack}
          src={LeftArrow}
          alt='Back'
          className='Notificaciones-Arrow cursor-pointer'
        />
        <Title
          text={GlobalService.uppercaseFirstLetter(t('services'))}
          color='black'
          size={'24px'}
          margin={'0px 0px 0px 30px'}
        />
      </div>
    </div>
  );

  /*********************
   * Responsive Values *
   *********************/
  const responsiveDocuments = isDocumentosLoading ? (
    <RegularSpinner />
  ) : (
    <div className='flex flex-col gap-y-2'>{documentos.length ? formattedDocuments : noData}</div>
  );

  const responsiveDocumentsTitle = (
    <Title
      text={`${GlobalService.uppercaseFirstLetter(t('documents'))}:`}
      color='black'
      size={'18px'}
      margin={'25px 0px 0px 0px'}
    />
  );

  const responsivePageTitle = GlobalService.uppercaseFirstLetter(t('patientHistory'));

  /*******
   * JSX *
   *******/
  return (
    <div className={`w-full ${isScreenBig ? '' : ''}`}>
      {isScreenBig ? (
        <>
          <TopMenu title={desktopPageTitle} userName={selectedPatient.title} />

          <div className='grid grid-cols-1 xl:grid-cols-2 gap-4 w-full p-5'>
            <div className='row-start-1 xl:hidden'>{desktopDocumentsTitle}</div>

            <div className='row-start-3 xl:row-span-1'>
              <div className='hidden xl:flex'>{desktopDocumentsTitle}</div>
              {desktopDocuments}
            </div>

            <div className='row-start-2 xl:row-span-2'>
              {patientTitle}
              {isPatientsLoading ? (
                <RegularSpinner />
              ) : (
                <div className={'rounded-md w-full p-4 relative mt-6 Personas-Card'}>
                  {formattedPatients}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <TopMenu
            title={responsivePageTitle}
            leftIcon={LeftArrowSmall}
            leftIconLink={comesFromAllPatient ? '/allPatients' : '/personas'}
          />

          <div className='ml-2 mr-1 my-24'>
            {patientTitle}

            {isPatientsLoading ? (
              <RegularSpinner />
            ) : (
              <div className={'rounded-md w-full mr-2 relative Personas-Card-Gray drop-shadow-xl'}>
                {formattedPatients}
              </div>
            )}

            {responsiveDocumentsTitle}

            {responsiveDocuments}
          </div>
        </>
      )}
    </div>
  );
};

export default DatosPersonaYServicio;
