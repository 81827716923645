import { useShowErrorPopup } from './use-show-error-popup';

export const useShowSelectedPatientErrorPopup = () => {
  const { showErrorPopup } = useShowErrorPopup();

  const showSelectedPatientErrorPopup = () => {
    showErrorPopup('errors.patientNotFound', '/allPatients');
  };

  return { showSelectedPatientErrorPopup };
};
