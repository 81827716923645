// ! File not in use
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Title from '../components/Title';
import { TopMenu } from '../components';
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import '../styles/Personas/Personas.css';
import LeftArrow from '../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';
import PagoConsentimiento from '../img/pagoConsentimiento.png';
import { useViewport, usePatientsValue, useSelectedPatient } from '../hooks';
import { SignForm } from '../components/sign/sign-form/index';
import { useUserSignData } from '../hooks/use-user/use-sign-data-user';
import { UserSignData } from 'src/ts/interfaces';

const Visitas = () => {
  const { patientId, reportId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patients, errorReason } = usePatientsValue();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();

  /**********
   * States *
   **********/
  const [errorText, setErrorText] = useState<string>('');

  const { viewportWidth } = useViewport();

  useEffect(() => {
    if (patients.length === 0) return;
    setSelectedPatient(patientId as string);
  }, [patients]);

  useEffect(() => {
    if (errorReason != null) {
      setErrorText(errorReason.message);
    }
  }, [selectedPatient]);

  const isScreenBig = viewportWidth > 768;
  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.mainTitle'))}:`;

  const desktopTopMenu = <TopMenu title={pageTitle} userName={selectedPatient.title} />;
  const desktopTitle = (
    <div className='flex text-left'>
      <img
        onClick={() => {
          navigate(`/informeDetallado/${patientId}/${reportId}`);
        }}
        src={LeftArrow}
        alt='Back'
        className='Notificaciones-Arrow cursor-pointer'
      />
      <Title
        text={`${GlobalService.uppercaseFirstLetter(t('pages.pagos.signatureTitle'))}`}
        color='black'
        size={'24px'}
        margin={'0px 0px 0px 30px'}
      />
    </div>
  );

  const responsivePageTitle = GlobalService.uppercaseFirstLetter(t('pages.pagos.signatureTitle'));

  const { userSignDataAsync } = useUserSignData();

  const handleSubmit = async (userSignData: UserSignData) => {
    try {
      console.log('IN SUBMIT');
      await userSignDataAsync(userSignData);
      navigate(`/informeDetallado/${patientId}/${reportId}/signature`);
    } catch (error: any) {
      throw new Error('Error handle submit ' + error.message);
    }
  };

  return (
    <div className={`w-full flex flex-col`}>
      {isScreenBig ? (
        <>
          {desktopTopMenu}
          <div className='w-full h-full m-2 flex flex-row gap-10 justify-even '>
            {desktopTitle}
            <div className='h-full flex flex-col gap-8 items-left justify-center'>
              <p className='max-w-xl font-bold'>
                {`${GlobalService.uppercaseFirstLetter(t('pages.pagos.payTextInfo'))}`}
              </p>
              <SignForm />
            </div>
            <div className='h-full flex flex-col gap-8 items-center justify-center'>
              <img
                className='h-auto w-full max-w-xs'
                src={PagoConsentimiento}
                alt='pago_consentimiento_img'
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='w-full flex flex-col justify-between'>
            <TopMenu
              title={responsivePageTitle}
              leftIcon={LeftArrowSmall}
              leftIconLink={`/informeDetallado/${patientId}/${reportId}`}
            />
            <div className='mt-[85px] md:mt-0 3xs:mx-2 md:ml-10 md:mr-2 md:w-full'>
              <div>
                <p className='text-left max-w-sm px-8 font-bold text-sm'>
                  {`${GlobalService.uppercaseFirstLetter(t('pages.pagos.payTextInfo'))}`}
                </p>

                <SignForm />

                <div className='flex flex-col items-center'>
                  <img
                    className='h-auto w-full max-w-xs'
                    src={PagoConsentimiento}
                    alt='pago_consentimiento_img'
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Visitas;
